import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import { useBooking } from './useBooking';
import {
  CreatePaymentGuaranteeIntentByReservationCriteriaMutation,
  CreatePaymentGuaranteeIntentByReservationCriteriaMutationVariables,
  CreatePaymentGuaranteeIntentByReservationCriteriaDocument,
  UpdateReservationIntentDocument,
} from '@/graphql/types.generated';

import { formatDateWithTimeslot } from '@/utils/date-utils';
import { A_LA_CARTE_OFFER } from '@/components/ContactForm/ContactForm';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { PaymentGuaranteeIntent } from '@/types/payment';

const usePaymentGuaranteeIntent = (): {
  loading: boolean;
  setupPaymentGuaranteeIntent: (
    reservationIntentUuid?: string,
  ) => Promise<
    | CreatePaymentGuaranteeIntentByReservationCriteriaMutation['createPaymentGuaranteeIntentByReservationCriteria']
    | undefined
  >;
  paymentGuaranteeIntent?: PaymentGuaranteeIntent;
} => {
  const [createPaymentGuaranteeIntentByReservationCriteriaMutation, { loading }] = useMutation<
    CreatePaymentGuaranteeIntentByReservationCriteriaMutation,
    CreatePaymentGuaranteeIntentByReservationCriteriaMutationVariables
  >(CreatePaymentGuaranteeIntentByReservationCriteriaDocument);

  const [updateReservationIntent] = useMutation(UpdateReservationIntentDocument);

  const {
    bookingParams: { pax: partySize, date, time, offer: offerUuid, customerUuid },
    restaurantUuid,
  } = useBooking();

  const { updateAuthData } = usePaymentContext();

  const [paymentGuaranteeIntent, setPaymentGuaranteeIntent] =
    useState<
      CreatePaymentGuaranteeIntentByReservationCriteriaMutation['createPaymentGuaranteeIntentByReservationCriteria']
    >();

  const setupPaymentGuaranteeIntent = useCallback(
    async (reservationIntentUuid?: string) => {
      setPaymentGuaranteeIntent(undefined);
      if (!restaurantUuid || !partySize || !customerUuid) {
        return;
      }
      const mealDate = formatDateWithTimeslot(String(date), Number(time), { withSecond: true });

      const variables = {
        reservationCriteria: {
          dateTime: mealDate,
          ...(offerUuid && offerUuid !== A_LA_CARTE_OFFER ? { offerUuid } : {}),
          partySize,
        },
        customerUuid,
        restaurantUuid,
      };

      const result = await createPaymentGuaranteeIntentByReservationCriteriaMutation({
        variables,
      });

      const paymentGuaranteeIntent = result.data?.createPaymentGuaranteeIntentByReservationCriteria;

      if (!paymentGuaranteeIntent) {
        throw new Error('[usePaymentGuaranteeIntent] Missing paymentGuaranteeIntent');
      }

      if (!paymentGuaranteeIntent.stripeAuthData) {
        throw new Error('[usePaymentGuaranteeIntent] Missing stripeAuthData');
      }

      setPaymentGuaranteeIntent(paymentGuaranteeIntent);
      updateAuthData(paymentGuaranteeIntent);

      if (reservationIntentUuid) {
        await updateReservationIntent({
          variables: {
            input: {
              id: reservationIntentUuid,
              paymentGuaranteeIntentUuid: paymentGuaranteeIntent.paymentGuaranteeIntentUuid,
            },
          },
        });
      }
      return paymentGuaranteeIntent;
    },
    [
      createPaymentGuaranteeIntentByReservationCriteriaMutation,
      customerUuid,
      date,
      offerUuid,
      partySize,
      restaurantUuid,
      time,
      updateAuthData,
      updateReservationIntent,
    ],
  );

  return { loading, paymentGuaranteeIntent, setupPaymentGuaranteeIntent };
};

export default usePaymentGuaranteeIntent;
