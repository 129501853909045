import {
  dateStrToDate,
  dateToTimeslot,
  dateToZonedDate,
  formatForGraphQl,
  timeslotToTimeHHMM,
} from '@/utils/date-utils';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';
import { IconBook, IconLocationPin } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { AfterSaleLayout, AfterSaleLayoutType } from '@/components/AfterSaleLayout/AfterSaleLayout';
import { TFBranding } from '@/components/TFBranding/TFBranding';
import { BookingSummary, BookingSummaryProps } from '@/components/BookingSummary/BookingSummary';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { CancelReservationDocument } from '@/graphql/client-backend.generated';
import { SeatingTimeMessage } from '@/components/SeatingTimeMessage/SeatingTimeMessage';
import { GetReservationDocument } from '@/graphql/types.generated';
import { useBooking } from '@/hooks/useBooking';
import { useSuccessPage, Step } from '@/hooks/useSuccessPage';
import { useError } from '@/contexts/ErrorProvider/ErrorProvider';
import { googleMapsUrl } from '@/utils/google/google-utils';
import { Loader } from '@/components/Loader/Loader';
import { Button } from '@/components/Button/Button';
import { Text } from '@/components/Text/Text';
import * as S from './SuccessStep.style';
import { captureException } from '@/utils/captureException';

export const SuccessStep = () => {
  const intl = useIntl();
  const {
    handleErrorGoBack,
    bookingParams: { reservationUuid },
    restaurantUuid,
  } = useBooking();
  const { widgetConfig, isWidgetCustomizable, isTfBrandingDisplayed } = useWidgetConfig();
  const timezone = widgetConfig?.restaurant?.timezone;
  const { setErrorMessage, closeError } = useError();
  const [cancelReservation, { loading: mutationLoading }] = useMutation(CancelReservationDocument);

  const [disabledGoBack, toggleGoBack] = useState(false);

  if (!restaurantUuid || !reservationUuid) {
    throw new Error('Missing restaurantUuid or reservationUuid');
  }

  const { data, error, loading } = useQuery(GetReservationDocument, {
    variables: { reservationUuid, restaurantUuid },
  });
  const { currentStep, props: successPageProps, changeStep, goToPreviousStep } = useSuccessPage(data?.reservation);

  if (loading) {
    return <Loader />;
  }

  if (error || !data || !data.reservation.mealDate) {
    throw new Error();
  }

  const mealDuration = data.reservation.seatingTime;
  const mealDate = dateToZonedDate(dateStrToDate(data.reservation.mealDate), timezone);
  const mealTime = dateToTimeslot(mealDate);

  const bookingSummaryProps: BookingSummaryProps = {
    pax: data.reservation.partySize ?? 1,
    date: formatForGraphQl(mealDate),
    hour: timeslotToTimeHHMM(mealTime),
    hasOffer: data.reservation.hasMenu || false,
    offerName: data.reservation.offer?.name,
    offerPrice: data.reservation.offer?.presetMenu.price?.value,
    offerCurrency: data.reservation.offer?.presetMenu.price?.currency,
    area: data.reservation.preferredArea?.externalName || data.reservation.preferredArea?.name || undefined,
    specialRequest: data.reservation.specialRequest || undefined,
    customFieldValues: data.reservation.customFieldValues,
    customFieldLabels: data.customFieldSchema.customFieldLabels,
    customFieldProperties: data.customFieldSchema.schema?.properties,
  };

  const restaurantName = widgetConfig?.restaurant?.name;
  const restaurantAddress = widgetConfig?.restaurant?.address;

  return (
    <AfterSaleLayout
      title={widgetConfig?.restaurant?.name}
      type={successPageProps?.type || AfterSaleLayoutType.CONFIRM}
      subtitle={successPageProps.subtitle}
    >
      <S.MainContainer>
        {currentStep === Step.HOME && (
          <div>
            <Text textAlign="center" m={0}>
              🎉{' '}
              <FormattedMessage
                id="tf_widget_thank_you_v2"
                defaultMessage="Thank you {firstName} for your booking."
                values={{
                  firstName: data.reservation.customer?.firstName,
                }}
              />
            </Text>
            <Text textAlign="center" m={0}>
              <FormattedMessage
                id="tf_widget_success_email_confirmation"
                defaultMessage="We just sent you a confirmation e-mail."
              />
            </Text>
          </div>
        )}
        {[Step.HOME_WAITING_LIST, Step.HOME_GROUP_REQUEST_WAITING_LIST].includes(currentStep) && (
          <Text textAlign="center" m={0}>
            <FormattedMessage
              id="tf_widget_thank_you_for_joining_waiting_list_v2"
              defaultMessage="Thank you {fistName} for joining the waiting list."
              values={{ fistName: data.reservation.customer?.firstName }}
            />
          </Text>
        )}
        {[Step.HOME_GROUP_REQUEST, Step.HOME_REQUEST].includes(currentStep) && (
          <Text textAlign="center" m={0}>
            <FormattedMessage
              id="tf_widget_thank_you_for_your_reservation_request"
              defaultMessage="Thank you {firstName} for your reservation request."
              values={{ firstName: data.reservation.customer?.firstName }}
            />
          </Text>
        )}
        {currentStep === Step.HOME_REQUEST && (
          <Text textAlign="center" m={0}>
            <FormattedMessage
              id="tf_widget_thank_you_will_receive_confirmation_once_verified"
              defaultMessage="You will receive a confirmation from the restaurant once it has been verified."
            />
          </Text>
        )}
        {[Step.HOME_GROUP_REQUEST, Step.HOME_GROUP_REQUEST_WAITING_LIST, Step.HOME_WAITING_LIST].includes(
          currentStep,
        ) && (
          <Text textAlign="center" m={0}>
            <FormattedMessage
              id="tf_widget_thank_you_receive_confirmation_later"
              defaultMessage="Your reservation is not guaranteed to be honored. You will receive a confirmation from the restaurant in case they find a table for you."
            />
          </Text>
        )}
        {[
          Step.HOME,
          Step.HOME_REQUEST,
          Step.HOME_GROUP_REQUEST,
          Step.HOME_WAITING_LIST,
          Step.HOME_GROUP_REQUEST_WAITING_LIST,
        ].includes(currentStep) && <BookingSummary {...bookingSummaryProps} />}
        {currentStep === Step.HOME && mealDuration != null && mealTime != null && (
          <SeatingTimeMessage mealDuration={mealDuration} mealStart={mealTime} />
        )}
        <S.ButtonLayout>
          {currentStep === Step.HOME && restaurantName && restaurantAddress && (
            <Button
              variant="secondary"
              data-testid="tf_widget_get_direction"
              onClick={() => {
                window.open(
                  googleMapsUrl({
                    address: restaurantAddress,
                    name: restaurantName,
                  }),
                );
              }}
            >
              <IconLocationPin /> <FormattedMessage id="tf_widget_get_directions" defaultMessage="Get directions" />
            </Button>
          )}
          {[
            Step.HOME_GROUP_REQUEST,
            Step.HOME_REQUEST,
            Step.HOME_WAITING_LIST,
            Step.HOME_GROUP_REQUEST_WAITING_LIST,
          ].includes(currentStep) && (
            <Button
              data-testid="tf_widget_book_another_table"
              disabled={disabledGoBack}
              isLoading={disabledGoBack}
              onClick={() => {
                toggleGoBack(true);
                handleErrorGoBack();
              }}
            >
              <IconBook /> <FormattedMessage id="tf_widget_book_another_table" defaultMessage="Book another table" />
            </Button>
          )}
          {[Step.HOME, Step.HOME_GROUP_REQUEST, Step.HOME_REQUEST].includes(currentStep) && (
            <Button variant="ghost" onClick={() => changeStep(Step.CANCEL)} data-testid="tf_widget_cancel_reservation">
              <FormattedMessage id="tf_widget_cancel_reservation" defaultMessage="Cancel the reservation" />
            </Button>
          )}
          {[Step.HOME_WAITING_LIST, Step.HOME_GROUP_REQUEST_WAITING_LIST].includes(currentStep) && (
            <Button
              variant="ghost"
              onClick={() => changeStep(Step.CANCEL)}
              data-testid="tf_widget_cancel_waiting_list_reservation"
            >
              <FormattedMessage
                id="tf_widget_cancel_waiting_list_reservation"
                defaultMessage="Cancel the waiting list reservation"
              />
            </Button>
          )}
          {currentStep === Step.CANCEL && (
            <>
              <Button
                data-testid="tf_widget_cancel_message_yes"
                disabled={mutationLoading}
                isLoading={mutationLoading}
                onClick={() => {
                  closeError();
                  cancelReservation({
                    variables: { reservationUuid: data.reservation.reservationUuid },
                    onError: (error) => {
                      setErrorMessage(
                        intl.formatMessage({
                          id: 'tf_widget_cancel_booking_error_message',
                          defaultMessage: 'Something went wrong. Please, try again.',
                        }),
                      );
                      captureException(new Error(`There was an error while canceling a booking: ${error}`));
                    },
                    onCompleted: () => {
                      changeStep(Step.CANCELED);
                    },
                  });
                }}
              >
                <FormattedMessage id="tf_widget_cancel_yes_message" defaultMessage="Yes, cancel the reservation" />
              </Button>
              <Button
                data-testid="tf_widget_cancel_message_no"
                variant="secondary"
                onClick={goToPreviousStep}
                disabled={mutationLoading}
              >
                <FormattedMessage id="tf_widget_cancel_no_message" defaultMessage="No, go back to my booking details" />
              </Button>
            </>
          )}
          {currentStep === Step.CANCELED && (
            <Button
              data-testid="tf_widget_book_another_table"
              disabled={disabledGoBack}
              isLoading={disabledGoBack}
              onClick={() => {
                toggleGoBack(true);
                handleErrorGoBack();
              }}
            >
              <FormattedMessage id="tf_widget_book_another_table" defaultMessage="Book another table" />
            </Button>
          )}
        </S.ButtonLayout>
      </S.MainContainer>
      {(!isWidgetCustomizable || isTfBrandingDisplayed) && (
        <S.SuccessFooter>
          <TFBranding isFooter />
        </S.SuccessFooter>
      )}
    </AfterSaleLayout>
  );
};
