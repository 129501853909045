import styled from '@emotion/styled';

import { Text } from '@/components/Text/Text';

export const ConditionsContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.space.doubleXxl};
`;

export const TermsWrapper = styled.div`
  text-align: center;
`;

export const TermsPrePaymentConditions = styled(Text)`
  margin-top: ${({ theme }) => theme.space.l};
  margin-bottom: ${({ theme }) => theme.space.s};
  font-weight: ${({ theme }) => theme.fontWeights.l};
  li {
    text-indent: ${({ theme }) => theme.space['-m']};
  }
`;

export const Terms = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
`;

export const TermsFee = styled(Text)`
  text-align: center;
`;
