import { AfterSaleLayoutProps, AfterSaleLayoutType } from '@/components/AfterSaleLayout/AfterSaleLayout';
import { Reservation } from '@/graphql/types.generated';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

export enum Step {
  HOME,
  HOME_GROUP_REQUEST,
  HOME_WAITING_LIST,
  HOME_GROUP_REQUEST_WAITING_LIST,
  CANCEL,
  CANCELED,
  HOME_REQUEST,
}

export enum STATUS {
  CANCELED = 'CANCELED',
  REQUESTED = 'REQUESTED',
}

export function useSuccessPage(reservation?: Reservation) {
  const intl = useIntl();
  const [currentStep, setStep] = useState(Step.HOME);
  const [previousStep, setPreviousStep] = useState(Step.HOME);

  useEffect(() => {
    if (reservation?.status === STATUS.CANCELED) {
      return setStep(Step.CANCELED);
    }
    if (reservation?.isGroup) {
      return setStep(Step.HOME_GROUP_REQUEST);
    }
    if (reservation?.isWaitingList) {
      return setStep(Step.HOME_WAITING_LIST);
    }
    if (reservation?.isGroup && reservation?.isWaitingList) {
      return setStep(Step.HOME_GROUP_REQUEST_WAITING_LIST);
    }
    if (reservation?.status === STATUS.REQUESTED) {
      return setStep(Step.HOME_REQUEST);
    }
    return setStep(Step.HOME);
  }, [reservation?.status, reservation?.isGroup, reservation?.isWaitingList]);

  const successPageProps = useMemo((): Pick<AfterSaleLayoutProps, 'subtitle' | 'type'> => {
    switch (currentStep) {
      case Step.HOME:
        return {
          subtitle: intl.formatMessage({
            id: 'tf_widget_success_message',
            defaultMessage: 'Your reservation is confirmed',
          }),
          type: AfterSaleLayoutType.CONFIRM,
        };
      case Step.HOME_GROUP_REQUEST:
        return reservation?.isWaitingList
          ? {
              subtitle: intl.formatMessage({
                id: 'tf_widget_success_waiting_list_message_v2',
                defaultMessage: 'You are on the waiting list',
              }),
              type: AfterSaleLayoutType.REQUEST,
            }
          : {
              subtitle: intl.formatMessage({
                id: 'tf_widget_success_group_request_waiting_message',
                defaultMessage: 'Your group request reservation is awaiting confirmation.',
              }),
              type: AfterSaleLayoutType.REQUEST,
            };
      case Step.HOME_REQUEST:
        return {
          subtitle: intl.formatMessage({
            id: 'tf_widget_success_request_waiting_message',
            defaultMessage: 'Your reservation is awaiting confirmation.',
          }),
          type: AfterSaleLayoutType.REQUEST,
        };
      case Step.HOME_WAITING_LIST:
        return {
          subtitle: intl.formatMessage({
            id: 'tf_widget_success_waiting_list_message_v2',
            defaultMessage: 'You are on the waiting list',
          }),
          type: AfterSaleLayoutType.WAITING_LIST,
        };
      case Step.HOME_GROUP_REQUEST_WAITING_LIST:
        return {
          subtitle: intl.formatMessage({
            id: 'tf_widget_success_group_request_waiting_list_message',
            defaultMessage: 'Your group request has been added to the waiting list.',
          }),
          type: AfterSaleLayoutType.WAITING_LIST,
        };
      case Step.CANCEL:
        return reservation?.isWaitingList
          ? {
              subtitle: intl.formatMessage({
                id: 'tf_widget_delete_waiting_list_booking_question_v2',
                defaultMessage: 'Do you really want to cancel your waiting list booking?',
              }),
              type: AfterSaleLayoutType.DESTROY,
            }
          : {
              subtitle: intl.formatMessage({
                id: 'tf_widget_delete_booking_question',
                defaultMessage: 'Do you really want to cancel your reservation?',
              }),
              type: AfterSaleLayoutType.DESTROY,
            };
      case Step.CANCELED:
        return {
          subtitle: intl.formatMessage({
            id: 'tf_widget_cancel_confirm_message',
            defaultMessage: 'Your reservation has been canceled',
          }),
          type: AfterSaleLayoutType.CANCELED,
        };
      default:
        return {
          subtitle: '',
          type: AfterSaleLayoutType.CONFIRM,
        };
    }
  }, [currentStep, intl, reservation?.isWaitingList]);

  return {
    currentStep: currentStep,
    previousStep: previousStep,
    props: successPageProps,
    changeStep: (step: Step) => {
      setPreviousStep(currentStep);
      setStep(step);
    },
    goToPreviousStep: () => {
      setStep(previousStep);
    },
  };
}
