import styled from '@emotion/styled';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import { Button } from '@/components/Button/Button';
import { ComponentProps } from 'react';
import { px2rem } from '@lafourchette/design-system-theming/utils';

export const TimeslotButton = styled((props: ComponentProps<typeof Button>) => (
  <Button variant="secondary" {...props} />
))`
  padding-left: ${({ theme }) => theme.space.s};
  padding-right: ${({ theme }) => theme.space.s};
  width: 100%;
  min-width: ${px2rem(70)};
`;

export const TimeslotDateButton = styled(TimeslotButton)`
  text-transform: capitalize;
  line-height: 1.125rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  height: 3.375rem;
`;

export const TimeslotOfferWrapper = styled((props: ComponentProps<typeof Flex>) => (
  <Flex alignItems="center" flexDirection="column" display="flex" {...props} />
))``;

export const OfferLabelContainer = styled.div`
  margin-top: ${({ theme }) => `calc(-1 * ${theme.space.s})`};
  position: relative;
  z-index: 2;
`;
