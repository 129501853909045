import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useBooking } from '@/hooks/useBooking';
import { Conditions } from '@/components/CreditCardGuarantee/Conditions/Conditions';
import { ReservationPreview } from '@/components/CreditCardGuarantee/ReservationPreview/ReservationPreview';
import { SubmitButton } from '@/components/CreditCardGuarantee/SubmitButton/SubmitButton';
import { ErrorModal } from '@/components/CreditCardGuarantee/CreditCardGuaranteeForm/ErrorModal/ErrorModal';
import { useCreditCardGuaranteeForm } from './hooks/useCreditCardGuaranteeForm';
import ContentLoader from 'react-content-loader';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import { StripeError, StripePaymentElementOptions } from '@stripe/stripe-js';
import { getStripeAction } from '../CreditCardGuaranteeWithStripe/CreditCardGuaranteeWithStripe';
import { UpdateReservationIntentDocument } from '@/graphql/types.generated';
import { useMutation } from '@apollo/client';
import usePaymentGuaranteeIntent from '@/hooks/usePaymentGuaranteeIntent';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';

export type CreditCardGuaranteeFormProps = {
  stripeClientSecret: string;
};

export const CreditCardGuaranteeForm: React.FC<CreditCardGuaranteeFormProps> = ({ stripeClientSecret }) => {
  const {
    bookingParams: { pax, time, date, reservationIntentUuid },
    restaurantUuid,
  } = useBooking();

  if (!pax || !time || !date || !restaurantUuid) {
    throw new Error(
      `Required context not provided : pax:${pax}, time:${time}, date:${date}, restaurantUuid:${restaurantUuid}`,
    );
  }

  const stripe = useStripe();
  const elements = useElements();
  const paymentData = usePaymentContext();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isStripeFormCompleted, setIsStripeFormCompleted] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [updateReservationIntent] = useMutation(UpdateReservationIntentDocument);
  const { setupPaymentGuaranteeIntent } = usePaymentGuaranteeIntent();

  const { handleCCGSubmit } = useCreditCardGuaranteeForm({
    reloadPaymentIntent: () => setupPaymentGuaranteeIntent(reservationIntentUuid),
  });

  if (!paymentData.config?.paymentProvider.authData) {
    return null;
  }

  const {
    config: {
      paymentProvider: { authData: stripeAuth },
    },
  } = paymentData;

  const stripePaymentElementOptions: StripePaymentElementOptions = {
    terms: {
      applePay: 'never',
      auBecsDebit: 'never',
      bancontact: 'never',
      card: 'never',
      cashapp: 'never',
      googlePay: 'never',
      ideal: 'auto',
      paypal: 'auto',
      sepaDebit: 'auto',
      sofort: 'auto',
      usBankAccount: 'auto',
    },
  };

  const handleError = (error: StripeError) => {
    setLoading(false);
    if (error) {
      setErrorMessage(error.message);
      setIsErrorModalOpen(true);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    if (!elements) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      if (reservationIntentUuid) {
        await updateReservationIntent({
          variables: {
            input: {
              id: reservationIntentUuid,
              paymentStepError: submitError.code || submitError.type,
            },
          },
        });
      }
      return;
    }

    if (stripeAuth.paymentGuaranteeIntent?.guaranteeType && stripeClientSecret) {
      const stripeAction = getStripeAction(stripeAuth.paymentGuaranteeIntent?.guaranteeType);

      // Confirm the SetupIntent using the details collected by the Payment Element
      const { error } = await stripe[stripeAction]({
        elements,
        clientSecret: stripeClientSecret,
        redirect: 'if_required',
      });

      if (error) {
        handleError(error);
        if (reservationIntentUuid) {
          await updateReservationIntent({
            variables: {
              input: {
                id: reservationIntentUuid,
                paymentStepError: error.code || error.type,
              },
            },
          });
        }
      } else {
        handleCCGSubmit(stripeAuth.paymentGuaranteeIntent?.paymentGuaranteeIntentUuid);
      }
    }
  };

  return (
    <div data-testid="payment-CCG-form">
      {!stripeClientSecret ? (
        <ContentLoader />
      ) : (
        <form onSubmit={handleSubmit}>
          <PaymentElement
            options={stripePaymentElementOptions}
            onChange={(event) => {
              setIsStripeFormCompleted(event.complete);
            }}
          />
          <Flex flexDirection="column" justifyContent="center">
            <ReservationPreview />
            <SubmitButton
              data-testid="CCG-form-submit"
              isSubmitAvailable={!!stripe && !loading && isStripeFormCompleted}
            />
            {isErrorModalOpen && (
              <ErrorModal errorMessage={errorMessage} handleModalClose={() => setIsErrorModalOpen(false)} />
            )}
            <Conditions />
          </Flex>
        </form>
      )}
    </div>
  );
};
