import { useController, UseControllerProps } from 'react-hook-form';
import { SelectHTMLAttributes } from 'react';
import { Select as CustomSelect } from '@/components/Select/Select';

type Option = string | number | boolean;
export type ChiliSelectOption = { label: string; value: Option };

type ChiliSelectProps = {
  prefix: string;
  label: string;
  options: ChiliSelectOption[];
  placeholder?: string;
  canHaveLegend?: boolean;
  legend?: string;
  flexGrow?: boolean;
  isDisabled?: boolean;
};

type SelectProps<T extends Record<string, unknown>> = UseControllerProps<T> &
  SelectHTMLAttributes<HTMLSelectElement> &
  ChiliSelectProps;

export const Select = <T extends Record<string, unknown>>({
  label,
  options,
  placeholder,
  prefix,
  canHaveLegend,
  legend,
  flexGrow,
  isDisabled,
  required,
  ...props
}: SelectProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);
  return (
    <CustomSelect<Option>
      {...field}
      value={field.value as Option /* FIXME find a better way to type this */}
      id={`${prefix}-${field.name}`}
      label={label}
      options={options}
      placeholder={placeholder}
      canHaveLegend={canHaveLegend}
      legend={error?.message || legend}
      flexGrow={flexGrow}
      isDisabled={isDisabled}
      status={error?.message ? 'error' : 'neutral'}
      required={required}
    />
  );
};
