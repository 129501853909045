import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';

export const TriangleSvg = styled.svg`
  & path {
    fill: ${({ theme }) => theme.customization.colors.svg.primary};
  }
`;

export const OfferIconWrapper = styled(Flex)`
  position: absolute;
  top: ${({ theme, size }) => (size === 'small' ? theme.space.halfXxs : theme.space.xxs)};
  right: ${({ theme, size }) => (size === 'small' ? theme.space.halfXxs : theme.space.xxs)};
  width: ${({ size }) => (size === 'small' ? px2rem(10) : px2rem(16))};
  height: ${({ size }) => (size === 'small' ? px2rem(10) : px2rem(16))};
`;
