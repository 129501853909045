import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import * as S from './MoreInfoForm.styles';

import { useBooking } from '@/hooks/useBooking';
import { Input } from '@/components/ReactHookForm/Input';
import { Select } from '@/components/ReactHookForm/Select';
import { Loader } from '@/components/Loader/Loader';
import { Button } from '@/components/Button/Button';
import { WizardStep } from '@/types/WizardBooking';

import { useMoreInfoForm } from '@/components/MoreInfoForm/hook/useMoreInfoForm';
import { CustomFieldName, CustomFieldProperties, OnSubmitSuccessData } from '@/components/MoreInfoForm/types';
import { ButtonContainer } from '@/components/ButtonContainer/ButtonContainer';
import { RequiredLegend } from '@/components/RequiredLegend/RequiredLegend';
import { AreaSelector } from '@/components/AreaSelector/AreaSelector';
import usePaymentGuaranteeIntent from '@/hooks/usePaymentGuaranteeIntent';

export const MoreInfoForm: React.FC = () => {
  const intl = useIntl();
  const { handleContactFormSuccess, handleSelectStep } = useBooking();
  const { setupPaymentGuaranteeIntent } = usePaymentGuaranteeIntent();

  const onSubmitSuccess = async (reservationInfo: OnSubmitSuccessData) => {
    await setupPaymentGuaranteeIntent(reservationInfo.reservationIntentUuid);
    handleSelectStep({
      ...reservationInfo,
      step: WizardStep.Payment,
    });
  };

  const {
    optionalCustomFields,
    mandatoryCustomFields,
    isLoading,
    showSeatingPreferences,
    isAreaMandatory,
    availableAreas,
    formState,
    control,
    isSubmitting,
    submitButtonLegend,
    handleSubmit,
    submitMoreInfoForm,
  } = useMoreInfoForm({
    onSubmitSuccess,
    onSubmitError: (dhpData) => {
      handleSelectStep({ ...dhpData, step: WizardStep.Error }, { onlyKeepDHP: true });
    },
    onBookingCreated: (reservationUuid) => {
      handleContactFormSuccess(reservationUuid);
    },
  });

  const isMandatoryCfListOdd = mandatoryCustomFields.length % 2 === 1;
  const isOptionalCfListOdd = optionalCustomFields.length % 2 === 1;
  const hasMandatoryFields = mandatoryCustomFields.length > 0 || (showSeatingPreferences && !!isAreaMandatory);

  if (isLoading) {
    return <Loader />;
  }

  const renderCustomField = (field: CustomFieldProperties, wholeRow: boolean, mandatory: boolean) => {
    const fieldName: CustomFieldName = `${mandatory ? 'mandatory' : 'optional'}CustomFields.${field.id}`;
    if (field.type === 'integer') {
      return (
        <S.CustomFieldWrapper key={field.id} wholeRow={wholeRow} data-testid="custom-field">
          <Select
            control={control}
            name={fieldName}
            options={Array.from(
              { length: (field.max ?? Number.MAX_SAFE_INTEGER) - (field.min ?? 0) + 1 },
              (_, i) => i + (field.min ?? 0),
            ).map((value) => ({
              value: value,
              label: value.toString(),
            }))}
            required={field.isRequired}
            label={field.title}
            data-id={field.id}
            canHaveLegend={false}
            placeholder={intl.formatMessage({
              id: 'tf_widget_select_value',
              defaultMessage: 'Select a value',
            })}
            prefix="more-information"
            flexGrow
          />
        </S.CustomFieldWrapper>
      );
    }
    if (field.enum || field.type === 'boolean') {
      return (
        <S.CustomFieldWrapper key={field.id} wholeRow={wholeRow} data-testid="custom-field">
          <Select
            control={control}
            name={fieldName}
            options={
              field.type === 'boolean'
                ? [
                    {
                      value: true,
                      label: intl.formatMessage({
                        id: 'tf_widget_yes',
                        defaultMessage: 'Yes',
                      }),
                    },
                    {
                      value: false,
                      label: intl.formatMessage({
                        id: 'tf_widget_no',
                        defaultMessage: 'No',
                      }),
                    },
                    {
                      value: '-1', // This is temporary till we rework dropdown component with a clear button
                      label: '',
                    },
                  ]
                : field.enum.map((fieldValue) => ({
                    value: fieldValue.id,
                    label: fieldValue.value,
                  }))
            }
            required={field.isRequired}
            label={field.title}
            data-id={field.id}
            canHaveLegend={false}
            placeholder={intl.formatMessage({
              id: 'tf_widget_select_value',
              defaultMessage: 'Select a value',
            })}
            prefix="more-information"
            flexGrow
          />
        </S.CustomFieldWrapper>
      );
    }

    return (
      <S.CustomFieldWrapper key={field.id} wholeRow={wholeRow} data-testid="custom-field">
        <Input
          control={control}
          type="text"
          name={fieldName}
          required={field.isRequired}
          label={field.title}
          id={fieldName}
          aria-labelledby={fieldName}
          prefix="more-information"
        />
      </S.CustomFieldWrapper>
    );
  };

  return (
    <S.Form noValidate onSubmit={handleSubmit(submitMoreInfoForm)}>
      <S.FormContainer>
        {showSeatingPreferences && (
          <S.InputContainer>
            <AreaSelector availableAreas={availableAreas} isAreaMandatory={isAreaMandatory} control={control} />
          </S.InputContainer>
        )}
        {mandatoryCustomFields.length > 0 && (
          <S.CustomFieldsGrid>
            {mandatoryCustomFields.map((field, fieldIndex) =>
              renderCustomField(field, fieldIndex === mandatoryCustomFields.length - 1 && isMandatoryCfListOdd, true),
            )}
          </S.CustomFieldsGrid>
        )}
        {hasMandatoryFields && (
          <S.OptionalFieldsTitle>
            <FormattedMessage id="tf_widget_contact_optional_fields" defaultMessage="Optional information" />
          </S.OptionalFieldsTitle>
        )}
        {optionalCustomFields.length > 0 && (
          <S.CustomFieldsGrid>
            {optionalCustomFields.map((field, fieldIndex) =>
              renderCustomField(field, fieldIndex === optionalCustomFields.length - 1 && isOptionalCfListOdd, false),
            )}
          </S.CustomFieldsGrid>
        )}
        <S.SpecialRequestsContainer>
          <Input
            control={control}
            name="specialRequest"
            prefix="contact-special-requests"
            label={intl.formatMessage({
              id: 'tf_widget_contact_special_requests',
              defaultMessage: 'Special Requests',
            })}
            placeholder={intl.formatMessage({
              id: 'tf_widget_contact_enter_special_requests',
              defaultMessage: 'You may enter any special requests',
            })}
            id="specialRequestInput"
            aria-labelledby="specialRequestInputLabel"
          />
        </S.SpecialRequestsContainer>
        {hasMandatoryFields && <RequiredLegend />}
      </S.FormContainer>
      <ButtonContainer>
        <Button
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !formState.isValid}
          data-testid="submit-booking-button"
          showArrow
        >
          {submitButtonLegend}
        </Button>
      </ButtonContainer>
    </S.Form>
  );
};
