import {
  IconCheckmark,
  IconExclamationmarkCircleFill,
  IconClockTimer,
  IconCross,
  IconPersonClock,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { formatCloudinaryImageUrl } from '@/utils/format-cloudinary-image-utils';
import { useRestaurantDisplay } from '@/hooks/useRestaurantDisplay';
import { useIntl } from 'react-intl';
import * as S from './AfterSaleLayout.styles';

export enum AfterSaleLayoutType {
  CONFIRM,
  DESTROY,
  REQUEST,
  WAITING_LIST,
  CANCELED,
}

export type AfterSaleLayoutProps = {
  title?: string;
  subtitle: string;
  type: AfterSaleLayoutType;
  children?: React.ReactNode;
};

const MAPPING_ICON_COMPONENT = {
  [AfterSaleLayoutType.CONFIRM]: <IconCheckmark data-testid="icon-checkmark" />,
  [AfterSaleLayoutType.DESTROY]: <IconExclamationmarkCircleFill data-testid="icon-exclamation" />,
  [AfterSaleLayoutType.REQUEST]: <IconClockTimer data-testid="icon-clock-timer" />,
  [AfterSaleLayoutType.WAITING_LIST]: <IconPersonClock data-testid="icon-person-clock" />,
  [AfterSaleLayoutType.CANCELED]: <IconCross data-testid="icon-cross" />,
};

const getIconComponent = (type: AfterSaleLayoutType) => {
  return MAPPING_ICON_COMPONENT[type];
};

export const AfterSaleLayout: React.FC<AfterSaleLayoutProps> = ({ title, subtitle, type, children }) => {
  const intl = useIntl();
  const { shouldDisplayRestaurantLogo, restaurantLogoUrl } = useRestaurantDisplay({});

  return (
    <S.FlexC>
      <S.HeaderBox>
        {shouldDisplayRestaurantLogo && (
          <S.HeaderLogo
            src={formatCloudinaryImageUrl({ url: restaurantLogoUrl || '', width: '80' })}
            alt={intl.formatMessage({
              id: 'tf_widget_restaurant_logo',
              defaultMessage: 'Restaurant logo',
            })}
            data-testid="restaurant-logo"
          />
        )}
        <S.HeaderTitle variant="title3" as="h1">
          {title}
        </S.HeaderTitle>
      </S.HeaderBox>
      <S.StatusBox type={type}>{getIconComponent(type)}</S.StatusBox>
      <S.SubtitleBox>{subtitle}</S.SubtitleBox>
      <S.MainContentBox>{children}</S.MainContentBox>
    </S.FlexC>
  );
};
