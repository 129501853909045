import styled from '@emotion/styled';
import { FOR_DESKTOP_ONLY, FOR_TABLET_AND_MORE } from '@/utils/emotion-utils';
import { Text } from '@/components/Text/Text';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import Grid from '@lafourchette/react-chili/dist/cjs/components/Atoms/Grid';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.l};
`;

export const FormContainer = styled.div`
  padding-bottom: ${px2rem(100)};
`;

export const InputContainer = styled(Grid)`
  gap: ${({ theme }) => theme.space.m};
  margin-top: ${({ theme }) => theme.space.m};
  margin-bottom: ${({ theme }) => theme.space.s};
  & > label {
    grid-template-rows: ${px2rem(20)} ${px2rem(26)} ${px2rem(2)};
  }
  grid-template-columns: repeat(auto-fit);
  ${FOR_TABLET_AND_MORE} {
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }
`;

export const CustomFieldsGrid = styled(Grid)`
  align-items: flex-end;
  grid-gap: ${({ theme }) => theme.space.m} 2.75%;
  margin-bottom: ${({ theme }) => theme.space.s};
  margin-top: ${({ theme }) => theme.space.m};
  ${FOR_TABLET_AND_MORE} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const CustomFieldWrapper = styled.div<{ wholeRow: boolean }>`
  display: inline-grid;
  width: 100%;
  ${FOR_TABLET_AND_MORE} {
    grid-column: ${({ wholeRow }) => (wholeRow ? 'span 2' : 'auto')};
  }
  & label {
    padding-top: 0;
  }
`;

export const OptionalFieldsTitle = styled((props: React.HTMLAttributes<HTMLHeadingElement>) => {
  return <Text variant="smallRegular" {...props}></Text>;
})`
  margin-top: ${({ theme }) => theme.space.l};
  margin-bottom: ${({ theme }) => theme.space.m};
  font-weight: ${({ theme }) => theme.fontWeights.s};
`;

export const SpecialRequestsContainer = styled(Box)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  & label {
    padding-top: 0;
  }
`;
