import * as S from './OfferIcon.styles';

type OfferIconProps = {
  'data-testid'?: string;
  size?: 'small' | 'medium';
};

export const OfferIcon = ({ 'data-testid': dataTestId, size }: OfferIconProps = { size: 'medium' }) => (
  <S.OfferIconWrapper size={size} alignItems="center" justifyContent="center">
    <S.TriangleSvg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={dataTestId}
    >
      <path
        id="Rectangle 4604"
        d="M0 0H10C11.1046 0 12 0.895431 12 2V11.9756C12 11.9846 11.9892 11.9891 11.9828 11.9827L0.145871 0.0607281C0.10728 0.0218597 0.0547725 0 0 0Z"
      />
    </S.TriangleSvg>
  </S.OfferIconWrapper>
);
