import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { Button } from '@/components/Button/Button';
import { ButtonContainer } from '@/components/ButtonContainer/ButtonContainer';
import { FormattedMessage } from 'react-intl';

export type SubmitButtonProps = {
  isSubmitAvailable: boolean;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({ isSubmitAvailable }) => {
  const paymentData = usePaymentContext();
  return (
    <ButtonContainer>
      <Button data-testid="submit-button" type="submit" disabled={!isSubmitAvailable} variant="primary">
        {paymentData.needsPayment && paymentData.config?.prepayment ? (
          <FormattedMessage
            id="tf_widget_creditCardGuarantee_submit"
            defaultMessage="Pay and confirm your reservation"
          />
        ) : (
          <FormattedMessage id="tf_widget_contact_submit" defaultMessage="Confirm your booking" />
        )}
      </Button>
    </ButtonContainer>
  );
};
