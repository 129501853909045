import Flex, { FlexProps } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';

export const ButtonLayout: React.FC<Omit<FlexProps, 'color'> & { children?: React.ReactNode }> = ({
  children,
  ...props
}) => (
  <Flex flexDirection="column" textAlign="center" mt="xl" gap={10} {...props}>
    {children}
  </Flex>
);
