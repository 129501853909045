import { Text } from '@/components/Text/Text';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';
import { VoucherDocument } from '@/graphql/types.generated';
import { useQuery } from '@apollo/client';
import { useBooking } from '@/hooks/useBooking';
import { Button } from '@/components/Button/Button';
import { Loader } from '@/components/Loader/Loader';
import { WizardStep } from '@/types/WizardBooking';
import { Heading } from '@/components/Heading/Heading';
import { getStatusCodeFromGqlError } from '@/utils/getStatusCodeFromGqlError';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import confettiLottie from './lottie/confetti.json';
import * as S from './CouponRecapStep.styles';

export const CouponRecapStep = ({}) => {
  const theme = useTheme();
  const intl = useIntl();
  const { bookingParams, restaurantUuid, previousSteps, handleSelectStep } = useBooking();

  const { data, loading, error } = useQuery(VoucherDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      voucherCode: bookingParams.couponCode ?? '',
    },
  });

  const previousStep = previousSteps.filter((step) => step !== WizardStep.CouponRecap).pop();

  if (loading) {
    return (
      <S.Container>
        <Loader />
      </S.Container>
    );
  }

  if (data?.voucher.isValid === false || (error && getStatusCodeFromGqlError(error.graphQLErrors[0]) === 404)) {
    const notValidMessage = intl.formatMessage(
      {
        id: 'tf_widget_voucherNotValidMessage',
        defaultMessage: 'The voucher with code {coupon} is not valid',
      },
      { coupon: `<strong>${bookingParams.couponCode}</strong>` },
    );

    return (
      <S.Container>
        <Text
          data-testid="recap-page-voucher-not-valid"
          dangerouslySetInnerHTML={{
            __html: notValidMessage,
          }}
        />
      </S.Container>
    );
  }

  if (error) {
    throw new Error(error.message);
  }

  return (
    <>
      <S.Container>
        <S.StyledLottie animationData={confettiLottie} loop={false} />
        <S.Header>
          <S.StyledIconVoucher color={theme.customization.colors.accent} />
          <Text variant="smallRegular">
            <FormattedMessage id="tf_widget_yourGiftVoucher" defaultMessage="Your gift voucher" />
          </Text>
        </S.Header>
        <Heading variant="title2" marginY={theme.space.s}>
          {data?.voucher.title}
        </Heading>
        <Text variant="body" marginY={theme.space.s}>
          {data?.voucher.subtitle}
        </Text>
        <Text variant="small">
          <strong>
            <FormattedMessage id="tf_widget_alsoIncludes" defaultMessage="Also includes:" />{' '}
          </strong>
          {data?.voucher.options.join(' + ')}
        </Text>

        <Flex>
          <Flex flexDirection="column" flex="1" alignItems="flex-start">
            <Text variant="small" marginY={theme.space.xs}>
              <FormattedMessage id="tf_widget_validFor" defaultMessage="Valid for" />
            </Text>
            <Flex alignItems="center">
              <S.StyledIconPerson />
              <Text variant="bodyRegular" marginY={theme.space.xs}>
                <FormattedMessage
                  id="tf_widget_voucherPaxMessage"
                  defaultMessage={'{count} {count, plural, one{Person} other{Persons}}'}
                  values={{ count: data?.voucher.pax }}
                />
              </Text>
            </Flex>
            <Text as="p" variant="small" textAlign="left" marginY={theme.space.xs}>
              <FormattedMessage
                id="tf_widget_voucherValidForInformations"
                defaultMessage="You can add supplementary diners to this booking and they will be charged at the restaurant."
              />
            </Text>
          </Flex>
          <Flex flexDirection="column" flex="1" alignItems="flex-start" ml={theme.space.m}>
            <Text variant="small" marginY={theme.space.xs}>
              <FormattedMessage id="tf_widget_validUntil" defaultMessage="Valid until" />
            </Text>
            <Flex alignItems="center">
              <S.StyledIconCalendar />
              <Text variant="bodyRegular" marginY={theme.space.xs}>
                <FormattedDate value={data?.voucher.expirationDate} />
              </Text>
            </Flex>
            <Text as="p" variant="small" textAlign="left" marginY={theme.space.xs}>
              <FormattedMessage
                id="tf_widget_voucherValidUntilInformations"
                defaultMessage="Reserve your meal using your gift voucher before the date above."
              />
            </Text>
          </Flex>
        </Flex>

        {data?.voucher.termsAndConditions && (
          <Box marginTop={theme.space.m}>
            <Text variant="microRegular">
              <FormattedMessage id="tf_widget_termsAndConditions" defaultMessage="Terms and conditions" />
            </Text>
            <Text variant="microLight">{data.voucher.termsAndConditions}</Text>
          </Box>
        )}
      </S.Container>

      <S.Actions>
        <Button isSmall onClick={() => handleSelectStep({ step: previousStep, couponCode: data?.voucher.code })}>
          <FormattedMessage id="tf_widget_reserveWithVoucher" defaultMessage="Reserve with voucher" />
        </Button>
        <Button variant="ghost" isSmall onClick={() => handleSelectStep({ step: previousStep, couponCode: null })}>
          <FormattedMessage id="tf_widget_doNotUseVoucher" defaultMessage="Do not use voucher" />
        </Button>
      </S.Actions>
    </>
  );
};
