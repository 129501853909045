import styled from '@emotion/styled';
import Lottie from 'lottie-react';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import { IconCalendar, IconPerson, IconVoucher } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';

const VOUCHER_CARD_MAX_HEIGHT = 500;
const VOUCHER_CARD_MIN_HEIGHT = 200;

export const Container = styled(Flex)`
  position: relative;
  flex-direction: column;
  background-color: ${({ theme }) => theme.customization.colors.grey100};
  border-radius: ${({ theme }) => theme.radii.l};
  padding: ${({ theme }) => theme.space.m} ${({ theme }) => theme.space.l};
  margin-top: ${({ theme }) => theme.space.l};
  text-align: center;
  max-height: ${VOUCHER_CARD_MAX_HEIGHT}px;
  min-height: ${VOUCHER_CARD_MIN_HEIGHT}px;
  overflow-y: auto;
`;

export const Header = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIconVoucher = styled(IconVoucher)`
  width: ${({ theme }) => theme.space.l};
  height: ${({ theme }) => theme.space.l};
`;

export const Actions = styled(Flex)`
  margin-top: ${({ theme }) => theme.space.l};
  padding: 0;
  gap: ${({ theme }) => theme.space.s};
  flex-direction: column;
`;

export const StyledIconPerson = styled(IconPerson)`
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
  margin-right: ${({ theme }) => theme.space.s};
`;

export const StyledIconCalendar = styled(IconCalendar)`
  width: ${({ theme }) => theme.space.m};
  height: ${({ theme }) => theme.space.m};
  margin-right: ${({ theme }) => theme.space.s};
`;

export const StyledLottie = styled(Lottie)`
  position: absolute;
  inset: 0;
`;
