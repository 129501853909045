import { useMemo, useState } from 'react';
import { IconPlusCircle } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { ButtonGrid } from '@/components/ButtonGrid/ButtonGrid';
import { GetAvailabilitiesDocument, GetPartySizesWithBestOfferDocument } from '@/graphql/types.generated';
import { useBooking, useDefaultDhpParams } from '@/hooks/useBooking';
import { useQuery } from '@apollo/client';
import { WizardStep } from '@/types/WizardBooking';
import { SkeletonRect } from '@/components/SkeletonRect/SkeletonRect';
import * as S from './PartySizeSelector.styles';
import { OfferIcon } from '../OfferIcon/OfferIcon';
import { AvailableOfferLegend } from '../AvailableOfferLegend/AvailableOfferLegend';

const SHOW_MORE_LIMIT = 20;
const MIN_PAX_NB = 4;

export const PartySizeSelector: React.FC = () => {
  const { handleSelectStep, bookingParams, restaurantUuid } = useBooking();
  const defaultDhpParams = useDefaultDhpParams();

  const [displayMoreBtn, setDisplayMoreBtn] = useState<boolean>(
    (bookingParams.pax || defaultDhpParams.pax) <= SHOW_MORE_LIMIT,
  );
  const [maxPax, setMaxPax] = useState<number>(MIN_PAX_NB);

  const {
    data: partySizesQueryResult,
    loading,
    error,
  } = useQuery(GetPartySizesWithBestOfferDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
    },
  });

  const isOfferAvailableForPax = (pax: number): boolean => {
    const partySize = partySizesQueryResult?.partySizesWithBestOffer?.find((ps) => ps.pax === pax);
  
    return Boolean(partySize?.bestOffer);
  };

  const partySizesRendering = useMemo(() => {
    const maxPaxNb = Math.max(
      partySizesQueryResult?.partySizesWithBestOffer?.reduce(
        (maxPax, value) => (maxPax < value.pax ? value.pax : maxPax),
        0,
      ) || 0,
      loading ? SHOW_MORE_LIMIT : MIN_PAX_NB,
    );
    setMaxPax(maxPaxNb);
    const partySizes: { pax: number; offerAvailable: boolean }[] = Array.from({ length: maxPax }, (_, index) => ({
      pax: index + 1,
      offerAvailable: isOfferAvailableForPax(index + 1),
    }));
    if (displayMoreBtn) {
      return partySizes.filter((partySize) => partySize.pax <= SHOW_MORE_LIMIT);
    }
    return partySizes;
  }, [displayMoreBtn, loading, maxPax, partySizesQueryResult?.partySizesWithBestOffer]);

  if (error) {
    throw new Error(error.message);
  }

  const partySizesAvailable =
    partySizesQueryResult?.partySizesWithBestOffer?.map((partySizeData) => partySizeData.pax) || [];

  let nextStep = WizardStep.Result;
  if (!bookingParams.date) {
    nextStep = WizardStep.Date;
  } else if (!bookingParams.time) {
    nextStep = WizardStep.Hour;
  }

  return (
    <S.Container>
      <ButtonGrid>
        {partySizesRendering.map((partySize) => (
          <div key={partySize.pax}>
            {loading ? (
              <SkeletonRect borderRadius={4} height={54} width="100%" />
            ) : (
              <S.PartySizeButton
                disabled={!partySizesAvailable.includes(partySize.pax)}
                isActive={
                  bookingParams.pax ? partySize.pax === bookingParams.pax : partySize.pax === defaultDhpParams.pax
                }
                key={partySize.pax}
                data-testid={`pax-${partySize.pax}`}
                onClick={() => {
                  if (nextStep === WizardStep.Result) {
                    // Keep data when doing a "back" from the result page.
                    handleSelectStep({ pax: partySize.pax }, { replaceHistory: true });
                  }
                  handleSelectStep({ pax: partySize.pax, step: nextStep });
                }}
              >
                {partySize.offerAvailable && (
                  <OfferIcon size="medium" data-testid={`offer-icon-pax-${partySize.pax}`} />
                )}
                {partySize.pax}
              </S.PartySizeButton>
            )}
          </div>
        ))}
        {maxPax > SHOW_MORE_LIMIT && displayMoreBtn && !loading && (
          <S.MoreButton data-testid="more-button" variant="ghost" onClick={() => setDisplayMoreBtn(false)}>
            <IconPlusCircle />
          </S.MoreButton>
        )}
      </ButtonGrid>
      <S.AvailableOfferLegendWrapper>
        <AvailableOfferLegend />
      </S.AvailableOfferLegendWrapper>
    </S.Container>
  );
};
