import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';

export const Container = styled(Flex)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.customization.colors.border.line};
`;

export const TitleBox = styled(Box)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.customization.colors.border.line};
`;

export const ContentFlex = styled(Flex)`
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.customization.colors.border.line};
`;

export const Title = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.s};
`;

export const Text = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.s};
`;

export const TextBold = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.l};
`;

export const TextSmall = styled(Box)`
  font-weight: ${({ theme }) => theme.fontWeights.s};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;
