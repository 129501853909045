import { IconArrowLeft } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { useIntl } from 'react-intl';
import { ErrorLayout } from '@/components/ErrorLayout/ErrorLayout';
import { RestaurantInMagIllustration } from './RestaurantInMag.illustration';

export type NoAvailabilitiesProps = {
  handleSelectStep?: Promise<boolean>;
};

export const NoAvailabilities: React.FC<NoAvailabilitiesProps> = ({ handleSelectStep }) => {
  const intl = useIntl();
  const title = intl.formatMessage({
    id: 'tf_widget_noAvailability_title',
    defaultMessage: 'No availability.',
  });
  const message = intl.formatMessage({
    id: 'tf_widget_noAvailability_message',
    defaultMessage: 'No dates available for this number of guests, date, and/or time selected.',
  });
  const actionLabel = intl.formatMessage({
    id: 'tf_widget_noAvailability_back',
    defaultMessage: 'Go back to previous step',
  });

  return (
    <ErrorLayout
      Illustration={RestaurantInMagIllustration}
      title={title}
      message={message}
      action={
        handleSelectStep
          ? {
              icon: <IconArrowLeft />,
              label: actionLabel,
              handleAction: () => handleSelectStep,
            }
          : undefined
      }
    />
  );
};
