import { NoAvailabilities } from '@/components/NoAvailabilities/NoAvailabilities';
import { OfferCard } from '@/components/OfferCard/OfferCard';
import { useBooking } from '@/hooks/useBooking';
import { useQuery } from '@apollo/client';
import { GetOffersForTimeslotDocument } from 'graphql/types.generated';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDateWithTimeslot } from '@/utils/date-utils';
import { SkeletonRect } from '../SkeletonRect/SkeletonRect';
import { WizardStep } from '@/types/WizardBooking';
import { A_LA_CARTE_OFFER } from '@/components/ContactForm/ContactForm';
import { Text } from '@/components/Text/Text';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import * as S from './OfferSelector.styles';

export const OfferSelector: React.FC = () => {
  const intl = useIntl();
  const paymentData = usePaymentContext(); // Data for classic booking.

  const {
    bookingParams: { pax, time, date, isWaitingList },
    restaurantUuid,
    handleSelectStep,
  } = useBooking();

  if (!pax || !time || !date || !restaurantUuid) {
    throw new Error(
      `Required context not provided : pax:${pax}, time:${time}, date:${date}, restaurantUuid:${restaurantUuid}`,
    );
  }

  const { data, loading, error } = useQuery(GetOffersForTimeslotDocument, {
    variables: {
      restaurantUuid,
      partySize: pax,
      date,
      timeslot: Number(time),
      dateTime: formatDateWithTimeslot(date, Number(time), { withSecond: true }), // to be removed when backend schema is updated
      includeWaitingList: isWaitingList,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  if (loading) {
    return (
      <S.OfferCards>
        {[...Array(3)].map((_, index) => (
          <SkeletonRect key={`skeleton-${index}`} height={129} />
        ))}
      </S.OfferCards>
    );
  }

  if (!data?.offersForTimeslot?.offers?.length) {
    return <NoAvailabilities handleSelectStep={handleSelectStep({ step: WizardStep.Result }, { onlyKeepDHP: true })} />;
  }

  return (
    <S.OfferCards>
      {data.offersForTimeslot.offers.map((offer, index) => {
        const isCCRequired =
          offer?.reservationCreditCardSettings?.imprint || offer?.reservationCreditCardSettings?.prepayment;

        return (
          <OfferCard
            dataTestId={`offer-card-button-${index + 1}`}
            key={offer.uuid}
            title={offer.name}
            price={offer.presetMenu.price?.value}
            currency={offer.presetMenu.price?.currency}
            hasReadMore={!!offer.description}
            isCCRequired={isCCRequired}
            validityPeriod={offer.validityPeriod}
            onSelect={() =>
              handleSelectStep({
                step: WizardStep.UserInformation,
                offer: offer.uuid,
              })
            }
          >
            <S.OfferContent>
              <Text variant="smallRegular" mb="0">
                {offer.description}
              </Text>

              {offer.presetMenu.sections.map((section, index) => (
                <Text key={section.name + index} variant="smallRegular" mb="0">
                  {section.items.map((item) => item.trim()).join(', ')}
                </Text>
              ))}

              {offer.note && (
                <Text variant="smallRegular" mb="0">
                  {offer.note}
                </Text>
              )}
            </S.OfferContent>
            {offer.presetMenu.sections.length ? (
              <S.Disclaimer>
                <FormattedMessage
                  id="tf_widget_offer_description_disclaimer_v2"
                  defaultMessage="Menu items may vary depending on availability."
                />
              </S.Disclaimer>
            ) : null}
          </OfferCard>
        );
      })}
      {data.offersForTimeslot.hasNormalStock && (
        <OfferCard
          dataTestId="no-offer-card-button"
          key={'classic-booking'}
          hasReadMore={false}
          title={intl.formatMessage({
            id: 'tf_widget_offer_simple_booking_title',
            defaultMessage: 'Classic booking',
          })}
          isCCRequired={paymentData.needsPayment}
          onSelect={() => handleSelectStep({ step: WizardStep.UserInformation, offer: A_LA_CARTE_OFFER })}
        >
          <FormattedMessage
            id="tf_widget_offer_simple_booking_description"
            defaultMessage="The standard “à la carte” reservation, without offer."
          />
        </OfferCard>
      )}
    </S.OfferCards>
  );
};
