import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import * as S from './ErrorModal.styles';

type ErrorModalProps = {
  errorMessage?: string;
  handleModalClose: () => void;
};

export const ErrorModal: React.FC<ErrorModalProps> = ({ errorMessage, handleModalClose }) => {
  const intl = useIntl();
  errorMessage =
    errorMessage ||
    intl.formatMessage({
      id: 'tf_widget_technicalError_title',
      defaultMessage: 'Something went wrong.',
    });

  return (
    <Modal
      title={intl.formatMessage({
        id: 'tf_widget_booking_cc_error_modal_title',
        defaultMessage: 'Warning',
      })}
      onClose={() => handleModalClose()}
    >
      <S.ErrorModalContent data-testid="error-modal-content">
        <S.ErrorModalIcon />
        <S.ErrorModalTitle>{errorMessage}</S.ErrorModalTitle>
        <S.ErrorModalText>
          <FormattedMessage
            id="tf_widget_booking_cc_error_modal_text"
            defaultMessage="Your booking has not been confirmed yet.<br></br>Try again to finalise your booking."
            values={{
              br: () => <br />,
            }}
          />
        </S.ErrorModalText>
        <S.ErrorModalButtonContainer>
          <Button variant="primary" mb="xs" width="100%" onClick={() => handleModalClose()}>
            <FormattedMessage id="tf_widget_booking_cc_error_modal_button_try_again" defaultMessage="Try again" />
          </Button>
        </S.ErrorModalButtonContainer>
      </S.ErrorModalContent>
    </Modal>
  );
};
