import { z } from 'zod';
import { CustomFieldProperties, CustomFieldValues } from '@/components/MoreInfoForm/types';

export const requiredCustomFieldsValidator = (
  value: CustomFieldValues | undefined,
  customFields: CustomFieldProperties[],
) => {
  if (value === undefined) {
    return false;
  }

  const hasInvalidField = customFields.some((field) => {
    const currValue = value[field.id];
    return field.isRequired && (currValue === undefined || currValue == -1 || currValue === '');
  });

  if (hasInvalidField) {
    return false;
  }

  return true;
};

const mandatoryCustomFieldSchema = z.record(z.union([z.string(), z.boolean(), z.number()]));
const optionalCustomFieldSchema = z.record(z.union([z.string(), z.boolean(), z.number(), z.undefined(), z.null()]));

export const getSchema = (mandatoryCustomFields: CustomFieldProperties[], isSeatingAreaMandatory?: boolean) => {
  return z.object({
    areaUuid: isSeatingAreaMandatory ? z.string().min(1) : z.string().optional(),
    optionalCustomFields: optionalCustomFieldSchema.or(z.object({})).optional(),
    ...(mandatoryCustomFields.length > 0 && {
      mandatoryCustomFields: mandatoryCustomFieldSchema.refine((value) =>
        requiredCustomFieldsValidator(value, mandatoryCustomFields),
      ),
    }),
    specialRequest: z.string().optional(),
  });
};
