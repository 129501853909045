import { ChiliSelectOption, Select } from '@/components/ReactHookForm/Select';
import { Control } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { Area } from '@/graphql/types.generated';
import { ReservationAdditionalInformation } from '@/components/MoreInfoForm/types';

type SelectableArea = Pick<Area, 'id' | 'externalName' | 'name'>;

export type AreaSelectorProps = {
  availableAreas?: Array<SelectableArea>;
  isAreaMandatory: boolean;
  control: Control<ReservationAdditionalInformation>;
};

export const AreaSelector: React.FC<AreaSelectorProps> = ({ availableAreas, isAreaMandatory, control }) => {
  const intl = useIntl();

  if (!availableAreas?.length) {
    return null;
  }

  const areaOptions = formatAreaOptions(availableAreas, isAreaMandatory, intl);

  return (
    <Select
      control={control}
      options={areaOptions}
      name="areaUuid"
      label={intl.formatMessage({
        id: 'tf_widget_area',
        defaultMessage: 'Area',
      })}
      placeholder={intl.formatMessage({
        id: 'tf_widget_area_type',
        defaultMessage: 'Select an area',
      })}
      prefix="seating-area-selector"
      required={isAreaMandatory}
      canHaveLegend={false}
      flexGrow
    />
  );
};

function formatArea({ externalName: label, id: value, name }: SelectableArea): ChiliSelectOption {
  return { value, label: label || name };
}

function formatAreaOptions(
  availableAreas: Array<SelectableArea>,
  isAreaMandatory: boolean,
  intl: IntlShape,
): Array<ChiliSelectOption> {
  const areaOptions = availableAreas.map(formatArea);
  if (isAreaMandatory) {
    return areaOptions;
  }
  return [
    ...areaOptions,
    {
      label: intl.formatMessage({
        id: 'tf_widget_any_area_value',
        defaultMessage: 'Any area',
      }),
      value: '', // Value null is not allowed on Select input.
    },
  ];
}
