import { FOR_DESKTOP_ONLY, FOR_MOBILE_ONLY } from '@/utils/emotion-utils';
import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';

export const OfferCards = styled(Box)`
  display: grid;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.space.l};

  ${FOR_DESKTOP_ONLY} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.space.m};
  }

  ${FOR_MOBILE_ONLY} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Disclaimer = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: ${({ theme }) => theme.fontWeights.l};
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  align-content: flex-end;
`;

export const OfferContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  white-space: pre-wrap;
`;
