import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import Text from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';
import { ComponentProps } from 'react';

import { css } from '@emotion/react';

export const Container = styled(Flex)(
  ({ theme }) => css`
    padding: ${theme.space.s} ${theme.space.halfL};
    gap: ${theme.space.s};
    border-radius: ${theme.radii.m};
    background: ${theme.customization.colors.grey100};
  `,
);

export const TimeslotContainer = styled.div`
  position: relative;
  width: ${px2rem(17)};
  height: ${px2rem(17)};
  flex-shrink: 0;
  border-radius: ${({ theme }) => theme.radii.s};
  border: ${px2rem(1)} solid ${({ theme }) => theme.customization.colors.grey600};
`;

export const Message = styled((props: ComponentProps<typeof Text>) => <Text variant="microRegular" {...props} />)`
  margin: 0;
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  letter-spacing: ${px2rem(0.28)};
`;
