import * as S from './ButtonGrid.styles';

type ButtonGridProps = {
  children?: React.ReactNode;
  className?: string;
};

export const ButtonGrid: React.FC<ButtonGridProps> = ({ children, className }) => {
  return (
    <S.GridContainer data-testid="button-grid" className={className}>
      {children}
    </S.GridContainer>
  );
};
