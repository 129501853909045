import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { A_LA_CARTE_OFFER } from '@/components/ContactForm/ContactForm';
import { useMutation, useQuery } from '@apollo/client';
import { useBooking } from '@/hooks/useBooking';
import { useError } from '@/contexts/ErrorProvider/ErrorProvider';

import {
  CreateWidgetReservationDocument,
  CreateReservationIntentDocument,
  CustomFieldSchema,
  GetFormInfoDocument,
  JsonSchema,
  WidgetReservationCreateInput,
  GetFormInfoQuery,
  ReservationIntentOriginEnum,
  UpdateReservationIntentDocument,
} from '@/graphql/types.generated';
import { get } from 'lodash';
import { API_ERRORS } from '@/utils/constants';
import { formatDateWithTimeslot, getZonedDateTimeFromDateAndTimeslot, timeslotToTimeHHMM } from '@/utils/date-utils';
import { setTrackingData } from '@/utils/tracking-data';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { getTranslationValue } from '@/utils/translation-utils';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { TranslationItemKeys } from '@/types/config';
import {
  BuildWidgetReservationCreatePayloadInput,
  CustomFieldProperties,
  CustomFieldValues,
  OnSubmitSuccessData,
  ReservationAdditionalInformation,
} from '@/components/MoreInfoForm/types';
import { getSchema } from '@/components/MoreInfoForm/MoreInfoForm.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { captureException } from '@/utils/captureException';

const clusterizeCustomFields = (customFields: CustomFieldProperties[]) => {
  return customFields.reduce<{
    mandatoryCustomFields: CustomFieldProperties[];
    optionalCustomFields: CustomFieldProperties[];
  }>(
    (acc, field) =>
      field.isRequired
        ? {
            mandatoryCustomFields: [...acc.mandatoryCustomFields, field],
            optionalCustomFields: acc.optionalCustomFields,
          }
        : {
            optionalCustomFields: [...acc.optionalCustomFields, field],
            mandatoryCustomFields: acc.mandatoryCustomFields,
          },
    { mandatoryCustomFields: [], optionalCustomFields: [] },
  );
};

const formatCustomFields = (customFieldSchema?: CustomFieldSchema) => {
  //TODO: this part will be moved to the api
  if (!customFieldSchema) return [];

  return Object.entries<JsonSchema['properties']>(customFieldSchema?.schema?.properties).map(([key, value]) => {
    const customField: CustomFieldProperties = {
      id: key,
      title: customFieldSchema.customFieldLabels[value.title],
      isRequired: customFieldSchema.schema?.required?.includes(key) || false,
      type: value.type,
      max: value.maximum,
      min: value.minimum,
      enum: value.enum?.map((enumId: string) => ({
        id: enumId,
        value: customFieldSchema.customFieldLabels[enumId],
      })),
    };
    return customField;
  });
};

export const removeEmptyValuesFromCustomFields = (customFields: CustomFieldValues): CustomFieldValues => {
  return Object.entries(customFields).reduce((acc, [key, value]) => {
    if (value !== '-1' && value !== '') {
      acc[key] = value;
    }
    return acc;
  }, {} as CustomFieldValues);
};

export const getSubmitButtonText = ({
  customButtonText,
  intl,
  isPaymentRequired,
  isWaitingList,
  isWidgetCustomizable,
}: {
  customButtonText: string;
  intl: IntlShape;
  isPaymentRequired: boolean;
  isWaitingList: boolean;
  isWidgetCustomizable: boolean;
}) => {
  if (isPaymentRequired) {
    return intl.formatMessage({
      id: 'tf_widget_contact_cta_next',
      defaultMessage: 'Next',
    });
  }
  if (isWaitingList) {
    return intl.formatMessage({
      id: 'tf_widget_contact_submit_waiting_list',
      defaultMessage: 'Confirm your waiting list booking',
    });
  }
  if (isWidgetCustomizable && customButtonText) {
    return customButtonText;
  }
  return intl.formatMessage({
    id: 'tf_widget_contact_submit',
    defaultMessage: 'Confirm your booking',
  });
};

const buildWidgetReservationCreatePayload = (
  formData: ReservationAdditionalInformation,
  inputData: BuildWidgetReservationCreatePayloadInput,
): WidgetReservationCreateInput => {
  const {
    customFields,
    restaurantUuid,
    date,
    time,
    pax,
    offer,
    isWaitingList,
    customerUuid,
    restaurantNewsletterOptIn,
    theForkNewsletterOptIn,
    crossSellUuid,
    utm_campaign,
    utm_medium,
    utm_source,
    mealDuration,
    couponCode,
    voucherData,
  } = inputData;

  const contactFormPayload: WidgetReservationCreateInput = {
    restaurantUuid,
    date: formatDateWithTimeslot(date, Number(time), { onlyDate: true }),
    time: timeslotToTimeHHMM(Number(time)),
    partySize: pax,
    isWaitingList: isWaitingList,
    customerUuid,
    restaurantNewsletterOptIn,
    theForkNewsletterOptIn,
    ...(formData.areaUuid && { areaUuid: formData.areaUuid }),
    offerUuid: offer && offer != A_LA_CARTE_OFFER ? offer : undefined,
    ...(Object.keys(customFields).length && { customFields }),
    specialRequest: formData.specialRequest || null, // Must send null instead of ''.
    mealDuration,
    couponCode,
    trackingData: setTrackingData({ crossSellUuid, utm_campaign, utm_medium, utm_source }),
    ...(couponCode && voucherData?.summary && { restaurantNote: voucherData.summary }),
  };

  return contactFormPayload;
};

const retrieveSeatingData = (getFormInfoData: GetFormInfoQuery | undefined) => {
  if (!getFormInfoData) {
    return {
      mealDuration: undefined,
      showSeatingPreferences: false,
      isAreaMandatory: false,
    };
  }
  const { seatingTime, availableAreas, restaurant } = getFormInfoData;
  const mealDuration = seatingTime?.mealDuration;
  const isAreaMandatory = availableAreas.isAreaMandatory;
  const hasSeatingsAvailabilities = Boolean(availableAreas.areas.length);
  const isAreaExclusiveOffer = Boolean(restaurant?.offer?.areaUuidList?.length);
  return {
    mealDuration,
    showSeatingPreferences: !isAreaExclusiveOffer && hasSeatingsAvailabilities,
    isAreaMandatory,
  };
};

export function useMoreInfoForm({
  onSubmitSuccess,
  onSubmitError,
  onBookingCreated,
}: {
  onSubmitSuccess: (data: OnSubmitSuccessData) => void;
  onSubmitError: (dhpData: { pax: number; date: string; time: string }) => void;
  onBookingCreated: (reservationUuid: string) => void;
}) {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { widgetConfig, isWidgetCustomizable } = useWidgetConfig();
  const paymentData = usePaymentContext();
  const { setErrorMessage, closeError } = useError();

  const {
    bookingParams: {
      pax,
      time,
      date,
      offer,
      customerUuid,
      theForkNewsletterOptIn,
      restaurantNewsletterOptIn,
      isWaitingList,
      crossSellUuid,
      couponCode,
      utm_campaign,
      utm_medium,
      utm_source,
      reservationIntentUuid,
      origin,
    },
    restaurantUuid,
  } = useBooking();

  if (!pax || !time || !date) {
    throw new Error(`Required DHP not provided - pax:${pax}, time:${time}, date:${date}`);
  }
  if (!restaurantUuid) {
    throw new Error(`Required restaurant data not provided - restaurantUuid: ${restaurantUuid}`);
  }
  if (!customerUuid) {
    throw new Error(`Required customerUuid not provided : customerUuid: ${customerUuid}`);
  }

  const {
    data: formInfoData,
    loading: isFormInfoLoading,
    error: formInfoError,
  } = useQuery(GetFormInfoDocument, {
    variables: {
      restaurantUuid,
      date,
      timeslot: Number(time),
      partySize: pax,
      offerUuid: offer ?? '',
      voucherCode: couponCode ?? '',
      skipOfferQuery: !offer || offer === A_LA_CARTE_OFFER,
      skipCustomFieldQuery: false,
      skipVoucherQuery: !couponCode,
    },
  });

  if (formInfoError) {
    throw new Error(formInfoError?.message);
  }

  const { isAreaMandatory, mealDuration, showSeatingPreferences } = retrieveSeatingData(formInfoData);

  const timezone = widgetConfig?.restaurant?.timezone;
  const mealDate = getZonedDateTimeFromDateAndTimeslot(date, time, timezone);

  const submitButtonLegend = getSubmitButtonText({
    customButtonText: getTranslationValue(
      widgetConfig?.settings,
      TranslationItemKeys.TRANSLATION_BUTTON_LEGEND,
      undefined,
    ),
    intl,
    isWaitingList,
    isWidgetCustomizable,
    isPaymentRequired: paymentData.needsPayment,
  });

  const genericErrorMessage = intl.formatMessage({
    id: 'tf_widget_submit_error_message',
    defaultMessage: `Something went wrong, please try again.`,
  });

  const [createWidgetReservation] = useMutation(CreateWidgetReservationDocument);
  const [createReservationIntent] = useMutation(CreateReservationIntentDocument);
  const [updateReservationIntent] = useMutation(UpdateReservationIntentDocument);

  const submitMoreInfoForm = async (formData: ReservationAdditionalInformation) => {
    closeError();
    setIsSubmitting(true);
    const customFields = removeEmptyValuesFromCustomFields({
      ...formData.mandatoryCustomFields,
      ...formData.optionalCustomFields,
    });

    if (paymentData.needsPayment) {
      let newReservationIntentUuid;
      if (!reservationIntentUuid) {
        const reservationIntent = await createReservationIntent({
          variables: {
            input: {
              restaurantUuid,
              customerUuid,
              customFields,
              specialRequests: formData.specialRequest,
              mealDate: mealDate?.toISOString(),
              partySize: pax,
              offerUuid: offer && offer != A_LA_CARTE_OFFER ? offer : undefined,
            },
          },
        });
        if (!reservationIntent.data) {
          setIsSubmitting(false);
          setErrorMessage(genericErrorMessage);
          captureException(
            new Error(`There was an error while creating reservation intent. Payload : ${JSON.stringify(formData)}`),
          );
          return;
        }
        newReservationIntentUuid = reservationIntent.data.createReservationIntent.id;
      }
      onSubmitSuccess({
        customerUuid,
        theForkNewsletterOptIn,
        restaurantNewsletterOptIn,
        specialRequest: formData.specialRequest,
        ...(Object.keys(customFields).length && { customFields: JSON.stringify(customFields) }),
        mealDuration,
        areaUuid: formData.areaUuid,
        reservationIntentUuid: newReservationIntentUuid || reservationIntentUuid || '',
      });
    } else {
      const contactFormPayload = buildWidgetReservationCreatePayload(formData, {
        customerUuid,
        customFields,
        date,
        isWaitingList,
        pax,
        restaurantNewsletterOptIn,
        restaurantUuid,
        theForkNewsletterOptIn,
        time,
        couponCode,
        crossSellUuid,
        mealDuration,
        offer,
        utm_campaign,
        utm_medium,
        utm_source,
        voucherData: formInfoData?.voucher,
      });
      createWidgetReservation({
        variables: { input: contactFormPayload },
        onError: (error) => {
          const errorName = get(error, 'graphQLErrors.0.extensions.exception.data.name', '');
          // Note: when an offer validation change, it means that the availabilities changed.
          // In this case, we estimate that the availabilities are not available anymore and redirect
          // to the "no more availability" page.
          if (
            [API_ERRORS.OFFER_VALIDATION_FAILED, API_ERRORS.RESTAURANT_DOES_NOT_HAVE_AVAILABILITIES].includes(errorName)
          ) {
            onSubmitError({ pax, date, time });
          } else {
            setIsSubmitting(false);
            setErrorMessage(genericErrorMessage);
          }
          captureException(
            new Error(
              `There was an error while confirming booking with contact form : ${error}. Payload : ${JSON.stringify(
                contactFormPayload,
              )}`,
            ),
          );
        },
        onCompleted: (data) => {
          if (data.createWidgetReservation.reservationUuid) {
            if (reservationIntentUuid) {
              updateReservationIntent({
                variables: {
                  input: {
                    id: reservationIntentUuid,
                    reservationUuid: data.createWidgetReservation.reservationUuid,
                    ...(origin === ReservationIntentOriginEnum.IncompleteReservationEmail && { origin }),
                  },
                },
              });
            }
            onBookingCreated(data.createWidgetReservation.reservationUuid);
          } else {
            setIsSubmitting(false);
            setErrorMessage(genericErrorMessage);
            captureException(
              new Error(
                `There was an error while confirming booking, missing reservationUuid. Payload : ${JSON.stringify(
                  data,
                )}`,
              ),
            );
          }
        },
      });
    }
  };

  const { optionalCustomFields, mandatoryCustomFields } = clusterizeCustomFields(
    formatCustomFields(formInfoData?.customFieldSchema),
  );

  const { formState, control, handleSubmit } = useForm<ReservationAdditionalInformation>({
    resolver: zodResolver(getSchema(mandatoryCustomFields, showSeatingPreferences && isAreaMandatory)),
    mode: 'onChange',
  });

  return {
    mealDuration,
    optionalCustomFields,
    mandatoryCustomFields,
    isAreaMandatory,
    showSeatingPreferences,
    availableAreas: formInfoData?.availableAreas.areas,
    isLoading: isFormInfoLoading,
    formState,
    control,
    isSubmitting,
    submitButtonLegend,
    handleSubmit,
    submitMoreInfoForm,
  };
}
