import React, { useEffect, useState } from 'react';
import { addMonths, differenceInCalendarMonths } from 'date-fns';
import { FormattedDate } from 'react-intl';
import { getCalendarInitIndex, getDaysInWeek, getWeeksInMonthMatrix, WeekStartsOnType } from './utils/calendar-utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import * as S from './NewCalendar.styles';
import 'swiper/css';
import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';

export type CalendarRenderCellProps = {
  day?: Date;
  carouselIndex: number;
};

export type CalendarProps = {
  selectedDate?: Date;
  locale?: string;
  startOfWeek: WeekStartsOnType;
  startDate: Date;
  endDate: Date;
  renderCell: (param: CalendarRenderCellProps) => React.ReactElement | null;
};

export const Calendar: React.FC<CalendarProps> = ({
  selectedDate,
  locale,
  startOfWeek,
  startDate,
  endDate,
  renderCell,
}) => {
  SwiperCore.use([Navigation]);
  const [swiper, setSwiper] = useState<SwiperCore | undefined>(undefined);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const monthCount = differenceInCalendarMonths(endDate, startDate) + 1;
  const initIndex = getCalendarInitIndex({ startDate, selectedDate });
  const monthCarousel = [...Array(monthCount)].map((_, index) =>
    getWeeksInMonthMatrix(7, 1, addMonths(startDate, index)),
  );

  useEffect(() => {
    setCarouselIndex(initIndex);
  }, [initIndex]);

  return (
    <>
      <div data-testid={`carousel-active-index-${carouselIndex}`} />
      <S.ButtonContainer>
        <S.ButtonPrev
          data-testid="calendar-prev"
          isSmall
          variant="ghost"
          onClick={() => {
            swiper?.slidePrev();
          }}
          disabled={carouselIndex === 0}
        >
          <S.IconChevronLeft />
        </S.ButtonPrev>
        <S.ButtonNext
          data-testid="calendar-next"
          isSmall
          variant="ghost"
          onClick={() => {
            swiper?.slideNext();
          }}
          disabled={carouselIndex >= monthCount - 1}
        >
          <S.IconChevronRight />
        </S.ButtonNext>
      </S.ButtonContainer>
      <Swiper
        onInit={(swiper: SwiperCore) => {
          setSwiper(swiper);
        }}
        navigation={false}
        initialSlide={initIndex}
        allowSlideNext={carouselIndex < monthCount - 1}
        allowSlidePrev={carouselIndex > 0}
        allowTouchMove={true}
        centeredSlides={true}
        autoplay={false}
        direction="horizontal"
        effect="slide"
        onSlideChange={({ activeIndex }: SwiperCore) => {
          setCarouselIndex(activeIndex);
        }}
        spaceBetween={20}
        speed={300}
      >
        {monthCarousel.map((weeks, carouselIndex) => (
          <S.CalendarMonthContainer key={`carousel-${carouselIndex}`}>
            <SwiperSlide key={`swiper-slide-${carouselIndex}`}>
              <S.CalendarMonth data-testid={`calendar-month-${carouselIndex}`}>
                <CalendarHeader locale={locale} startOfWeek={startOfWeek} weeks={weeks} />
                <S.CalendarGrid>
                  {weeks.map((week, weekIndex) =>
                    week.map((day, dayIndex) => (
                      <div key={`carousel-${carouselIndex}-week-${weekIndex}-day-${dayIndex}`}>
                        {renderCell({
                          day,
                          carouselIndex,
                        })}
                      </div>
                    )),
                  )}
                </S.CalendarGrid>
              </S.CalendarMonth>
            </SwiperSlide>
          </S.CalendarMonthContainer>
        ))}
      </Swiper>
    </>
  );
};

type CalendarHeaderProps = {
  locale?: string;
  startOfWeek: WeekStartsOnType;
  weeks: Date[][];
};

const CalendarHeader: React.FC<CalendarHeaderProps> = ({ locale, weeks, startOfWeek }) => {
  const { restaurantDate } = useRestaurantDate();
  return (
    <S.CalendarDiv>
      <S.CalendarMonthCaption>
        <FormattedDate value={weeks[1][0]} month="long" year={'numeric'} />
      </S.CalendarMonthCaption>
      <S.CalendarMonthDiv>
        {getDaysInWeek(restaurantDate, startOfWeek).map((day, index) => (
          <S.CalendarDayText key={`day-index-${index}`}>
            <FormattedDate value={day} weekday={locale === 'pt-PT' ? 'narrow' : 'short'} />
          </S.CalendarDayText>
        ))}
      </S.CalendarMonthDiv>
    </S.CalendarDiv>
  );
};
