import {
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  getDay,
  isPast,
  startOfMonth,
  startOfWeek,
  differenceInCalendarMonths,
} from 'date-fns';

export type WeekStartsOnType = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const getDaysInWeek = (restaurantDate: Date, weekStartsOn: WeekStartsOnType = 1) => {
  const week = eachDayOfInterval({
    start: startOfWeek(restaurantDate, { weekStartsOn }),
    end: endOfWeek(restaurantDate, { weekStartsOn }),
  });
  return week;
};

export const getCalendarInitIndex = ({ startDate, selectedDate }: { startDate: Date; selectedDate?: Date }): number => {
  if (!selectedDate || isPast(selectedDate)) {
    return 0;
  }
  return Math.abs(differenceInCalendarMonths(startDate, selectedDate));
};

export const getWeeksInMonthMatrix = (daysInWeek: number, weekStartsOn: WeekStartsOnType, monthDay: Date): Date[][] => {
  let month = eachDayOfInterval({ start: startOfMonth(monthDay), end: endOfMonth(monthDay) });

  // getDay() => sunday - saturday / 0 - 6
  // weekStartsOn => monday = 1, so we have to convert sunday = 0 to sunday = 7 to calculate
  const handleSunday = getDay(month[0]) === 0 ? 7 : getDay(month[0]);

  // not a monday
  if (handleSunday > weekStartsOn) {
    month = [...new Array(handleSunday - weekStartsOn).fill(null), ...month];
  }

  const weeks: Date[][] = [];
  for (let index = 0; index < month.length; index += daysInWeek) {
    const week = month.slice(index, index + daysInWeek);
    weeks.push(week);
  }
  return weeks;
};
