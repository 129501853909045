import { TimeslotCard } from '@/components/TimeslotCard/TimeslotCard';
import { FormattedMessage } from 'react-intl';
import { TimeslotFragment } from '@/graphql/types.generated';
import { useBooking } from '@/hooks/useBooking';
import { dateTimeToDate, dateTimeToDateString, timeslotToTimeHHMM } from '@/utils/date-utils';
import { WizardStep } from '@/types/WizardBooking';
import { IconPersonClock } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { Button } from '@/components/Button/Button';
import * as S from './BookingResultScreen.styles';

export type BookingResultScreenProps = {
  timeslots: TimeslotFragment[];
  waitingListSlot?: TimeslotFragment;
};

export const BookingResultScreen = ({ timeslots, waitingListSlot }: BookingResultScreenProps) => {
  const { handleSelectStep } = useBooking();

  return (
    <div>
      <S.Title variant="title2" as="h2">
        <FormattedMessage id="tf_widget_bookingResult_title_v2" defaultMessage="Choose an available time slot" />
      </S.Title>
      <S.ButtonGridStyled>
        {timeslots.map((timeslot) => {
          const isOffer = !!timeslot.bestOffer?.offerType;
          return (
            <TimeslotCard
              key={timeslot.datetime}
              handleSelectStep={() => {
                return handleSelectStep({
                  date: dateTimeToDateString(timeslot.datetime),
                  time: String(timeslot.time),
                  step: isOffer ? WizardStep.Offer : WizardStep.UserInformation,
                });
              }}
              date={dateTimeToDate(timeslot.datetime)}
              offerAvailable={isOffer}
            />
          );
        })}
      </S.ButtonGridStyled>
      {!!waitingListSlot && (
        <>
          <S.ButtonContainer>
            <Button
              variant="ghost"
              isSmall
              onClick={() => {
                const isOffer = !!waitingListSlot.bestOffer?.offerType;
                return handleSelectStep({
                  date: dateTimeToDateString(waitingListSlot.datetime),
                  time: String(waitingListSlot.time),
                  step: isOffer ? WizardStep.Offer : WizardStep.UserInformation,
                  isWaitingList: true,
                });
              }}
              data-testid="join-waiting-list-button"
            >
              <IconPersonClock />{' '}
              <FormattedMessage
                id="tf_widget_noSlot_join_waiting_list"
                defaultMessage="Join the waiting list for {time}"
                values={{
                  time: timeslotToTimeHHMM(Number(waitingListSlot.time)),
                }}
              />
            </Button>
          </S.ButtonContainer>
        </>
      )}
    </div>
  );
};
