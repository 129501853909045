import styled from '@emotion/styled';
import { Heading } from '@/components/Heading/Heading';
import { ButtonGrid } from '@/components/ButtonGrid/ButtonGrid';
import { MainContentBoxMaxWidth } from '@/components/WizardLayout/WizardLayout.styles';

export const Title = styled(Heading)`
  text-align: center;
`;

export const ButtonGridStyled = styled(ButtonGrid)`
  max-width: ${MainContentBoxMaxWidth.S};
  margin: 0 auto;
`;

export const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.l};
  text-align: center;
`;
