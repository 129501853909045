import { Heading } from '@/components/Heading/Heading';
import { Button as ButtonComponent } from '@/components/Button/Button';
import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.space.l};
`;

export const Title = styled(Heading)`
  display: flex;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.space.s};
  margin: 0;
`;

export const Button = styled(ButtonComponent)`
  min-width: 80%;
`;
