import { NoSlotAvailableStep } from '@/components/NoSlotAvailableStep/NoSlotAvailableStep';
import { useMemo } from 'react';
import { BookingResultScreen } from '@/components/BookingResultScreen/BookingResultScreen';
import { useQuery } from '@apollo/client';
import { GetTimeslotsDocument } from '@/graphql/types.generated';
import { useBooking } from '@/hooks/useBooking';
import { SkeletonRect } from '../SkeletonRect/SkeletonRect';
import { AvailabilityType } from '@/types/AvailabilityType';
import { WizardStep } from '@/types/WizardBooking';
import * as S from './ResultStep.styles';

const MINUTES_GAP = 60;

export const ResultStep = () => {
  const {
    bookingParams: { pax, date, time },
    restaurantUuid,
    handleSelectStep,
  } = useBooking();

  if (pax == null || !date || !restaurantUuid || !time) {
    throw new Error(
      `Required context not provided: pax:${pax}, date:${date}, restaurantUuid:${restaurantUuid}, time:${time}`,
    );
  }

  const {
    data: timeslots,
    loading,
    error,
  } = useQuery(GetTimeslotsDocument, {
    variables: {
      restaurantUuid,
      date,
      partySize: pax,
      includeWaitingList: true,
      timeslotFilter: {
        after: { timeslot: +time - MINUTES_GAP, included: true },
        before: { timeslot: +time + MINUTES_GAP, included: true },
      },
    },
  });

  const { waitingListSlot, availableTimeslots, exactMatch } = useMemo(() => {
    return {
      waitingListSlot: timeslots?.timeslots.find(
        (timeslot) =>
          timeslot.availabilityType === AvailabilityType.WAITING_LIST_AVAILABILITY && timeslot.time === Number(time),
      ),
      availableTimeslots: timeslots?.timeslots.filter(
        (timeslot) => timeslot.availabilityType === AvailabilityType.AVAILABILITY,
      ),
      exactMatch: timeslots?.timeslots.find(
        (timeslot) => timeslot.availabilityType === AvailabilityType.AVAILABILITY && timeslot.time === Number(time),
      ),
    };
  }, [timeslots, time]);

  if (loading) {
    return (
      <div data-testid="loaderSkeleton" style={{ margin: 'auto', maxWidth: 480 }}>
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <SkeletonRect height={28} uniqueKey="title" />
        </div>
        <S.StyledButtonGrid>
          {[...Array(4)].map((_, index) => (
            <SkeletonRect key={`skeleton-${index}`} height={46} uniqueKey="buttons" />
          ))}
        </S.StyledButtonGrid>
      </div>
    );
  }

  if (error) {
    throw new Error(error.message);
  }

  if (!!exactMatch) {
    handleSelectStep(
      {
        step: !!exactMatch.bestOffer?.offerType ? WizardStep.Offer : WizardStep.UserInformation,
      },
      { replaceHistory: true },
    );
    return null;
  }

  return (
    <S.Container>
      {availableTimeslots && availableTimeslots.length > 0 ? (
        <BookingResultScreen waitingListSlot={waitingListSlot} timeslots={availableTimeslots} />
      ) : (
        <NoSlotAvailableStep waitingListSlot={waitingListSlot} />
      )}
    </S.Container>
  );
};
