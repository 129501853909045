import { useCallback } from 'react';
import { useIntl } from 'react-intl';

// If the error has a type, then it is a StripeError, we return the error message to be shown.
const isStripeError = (error: unknown): error is { message?: string } =>
  !!error && typeof error === 'object' && 'type' in error && 'message' in error;

export const usePaymentErrors = () => {
  const intl = useIntl();
  const getPaymentErrorMessage = useCallback(
    (error: unknown) => {
      const defaultMessage = intl.formatMessage({
        id: 'tf_widget_error_payment_generic_error',
        defaultMessage: 'Error processing your payment',
      });
      if (isStripeError(error)) {
        return error.message ?? defaultMessage; // Return "defaultMessage" in case "message" is undefined.
      }
      // Other kind of error that we don't want to show, return the default error message instead.
      return defaultMessage;
    },
    [intl],
  );
  return getPaymentErrorMessage;
};
