import { Heading } from '@/components/Heading/Heading';
import styled from '@emotion/styled';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import { ComponentProps } from 'react';
import { AfterSaleLayoutType } from './AfterSaleLayout';

export const FlexC = styled(Flex)`
  flex-direction: column;
  align-items: center;
`;

export const HeaderBox = styled((props: ComponentProps<typeof Flex>) => <Flex m={0} {...props} />)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.m};
  width: 100%;
  background: ${({ theme }) => theme.customization.colors.cta.primary.default};
  background-size: 'cover';
  font-family: ${({ theme }) => theme.customization.fonts.body};
  padding: ${({ theme }) => `${theme.space.l} 0 56px 0`};
`;

export const HeaderTitle = styled((props: ComponentProps<typeof Heading>) => (
  <Heading m={0} textAlign="center" {...props} />
))`
  color: ${({ theme }) => theme.customization.colors.text.onCta.primary.default};
`;

const StatusBoxContainer = styled(Box)<{ type: AfterSaleLayoutType }>`
  display: flex;
  justify-content: center;
  margin-top: -2rem;
  background: ${({ theme }) => theme.customization.colors.background};
  border-radius: 50%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.customization.colors.cta.primary.default};
    color: ${({ theme }) => theme.customization.colors.text.onAccent};
    border-radius: 50%;
    padding: ${({ theme }) => theme.space.m};
    border: 4px solid;
    border-color: ${({ theme }) => theme.customization.colors.background};
  }

  svg {
    display: block;
    width: ${({ theme }) => theme.space.l};
    height: ${({ theme }) => theme.space.l};
  }
`;

type StatusBoxProps = {
  type: AfterSaleLayoutType;
  children?: React.ReactNode;
};

export const StatusBox: React.FC<StatusBoxProps> = ({ type, children }) => (
  <StatusBoxContainer type={type} data-testid="status-box-container">
    <div>{children}</div>
  </StatusBoxContainer>
);

export const SubtitleBox = styled(Box)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  font-weight: bold;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.l};
  margin: ${({ theme }) => theme.space.m} auto;
  padding-left: ${({ theme }) => theme.space.m};
  padding-right: ${({ theme }) => theme.space.m};
`;

export const MainContentBox = styled(Box)`
  width: 100%;
  max-width: 500px;
  color: ${({ theme }) => theme.customization.colors.text.default};
  padding: ${({ theme }) => theme.space.m};
`;

export const HeaderLogo = styled.img`
  justify-self: center;
  align-self: center;
  max-width: 65px;
  max-height: 65px;
`;
