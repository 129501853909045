import { SisterRestaurantCard } from '@/components/SisterRestaurantCard/SisterRestaurantCard';
import { useBooking } from '@/hooks/useBooking';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { Loader } from '@/components/Loader/Loader';
import { Button } from '@/components/Button/Button';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { SearchSisterRestaurantsDocument } from '@/graphql/types.generated';
import { useState } from 'react';
import * as S from './SisterRestaurantsList.styles';

export const SisterRestaurantsList = () => {
  const {
    restaurantUuid,
    bookingParams: { pax, date, time },
  } = useBooking();
  const { widgetConfig } = useWidgetConfig();

  const SEARCH_DISTANCE_METERS = 30000;
  const MIN_60 = 60;
  const SISTER_R_DISPLAY_START = 6;
  const SISTER_R_DISPLAY_INCREMENT = 9;

  const [nbSisterRestaurantToDisplay, setNbSisterRestaurantsToDisplay] = useState(SISTER_R_DISPLAY_START);

  const {
    data: sisterRestaurants,
    loading,
    error,
  } = useQuery(SearchSisterRestaurantsDocument, {
    variables: {
      limitDistanceInMeters: SEARCH_DISTANCE_METERS,
      restaurantUuid: String(restaurantUuid),
      partySize: Number(pax),
      date: String(date),
      timeslotFilter: {
        after: {
          timeslot: Number(time) - MIN_60,
          included: true,
        },
        before: {
          timeslot: Number(time) + MIN_60,
          included: true,
        },
      },
    },
  });

  const incrementNbSisterRestaurantsToDisplay = () => {
    setNbSisterRestaurantsToDisplay(nbSisterRestaurantToDisplay + SISTER_R_DISPLAY_INCREMENT);
  };

  const sisterRestaurantsCount = sisterRestaurants?.searchSisterRestaurants.length || 0;

  if (loading) {
    return <Loader />;
  }

  if (error || !widgetConfig || !date || !pax || !restaurantUuid || sisterRestaurantsCount === 0) {
    return <div data-testid="sister-restaurant-list-no-result" />;
  }

  return (
    <div data-testid="sister-restaurant-list-result">
      <S.TitleContainer variant="title2">
        <S.RestaurantCutlery />
        <FormattedMessage id="tf_widget_sister_restaurant_list_title" defaultMessage="Try our other places" />
      </S.TitleContainer>
      <S.ListContainer nbRestaurants={sisterRestaurantsCount || 1}>
        {sisterRestaurants?.searchSisterRestaurants
          .slice(0, nbSisterRestaurantToDisplay)
          .map((restaurant, index) => (
            <SisterRestaurantCard
              key={`${restaurant.restaurantUuid}-${index}`}
              sisterRestaurant={restaurant}
              currentRestaurantName={widgetConfig.restaurant?.name || ''}
              pax={pax}
              date={date}
              originRestaurantUuid={String(restaurantUuid)}
              dataTestId={`sister-card-${index}`}
            />
          ))}
      </S.ListContainer>
      {sisterRestaurants && sisterRestaurantsCount > nbSisterRestaurantToDisplay && (
        <S.PaginationActionContainer>
          <Button isSmall variant="ghost" onClick={incrementNbSisterRestaurantsToDisplay}>
            <S.Magnifyingglass />
            <FormattedMessage id="tf_widget_sister_restaurant_list_pagination" defaultMessage="Show more" />
          </Button>
        </S.PaginationActionContainer>
      )}
    </div>
  );
};
