import { Calendar, CalendarRenderCellProps } from '@/react-chili/NewCalendar/NewCalendar';
import { useDefaultDhpParams, useBooking } from '@/hooks/useBooking';
import { addDays } from 'date-fns';
import { useRouter } from 'next/router';
import { FormattedDate } from 'react-intl';
import { formatForGraphQl, dateStrToDate, dateCopy } from '@/utils/date-utils';
import { SkeletonRect } from '@/components/SkeletonRect/SkeletonRect';
import { WizardStep } from '@/types/WizardBooking';
import { useRestaurantDate } from '@/contexts/RestaurantDateProvider/RestaurantDateProvider';
import { useWidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { GetAvailabilitiesDocument } from '@/graphql/types.generated';
import { useQuery } from '@apollo/client';
import * as S from './DateSelector.styles';
import { AvailableOfferLegend } from '../AvailableOfferLegend/AvailableOfferLegend';
import { OfferIcon } from '../OfferIcon/OfferIcon';

export const DateSelector = () => {
  const { handleSelectStep, bookingParams, restaurantUuid } = useBooking();
  const { locale } = useRouter();
  const { widgetConfig } = useWidgetConfig();
  const defaultDhpParams = useDefaultDhpParams();
  const { restaurantDate } = useRestaurantDate();

  const maxDays = widgetConfig?.restaurant?.webMaxDays || 90; // TODO: remove default
  const startOfWeek = 1;
  const startDate = dateCopy(restaurantDate);
  const endDate = addDays(startDate, maxDays - 1); // Availabilities are computed with maxDays minus 1, we need to remove one day to stay compliant.

  const {
    data: availabilities,
    loading,
    error,
  } = useQuery(GetAvailabilitiesDocument, {
    variables: {
      restaurantUuid: String(restaurantUuid),
      startDate: formatForGraphQl(startDate),
      endDate: formatForGraphQl(endDate),
      includeWaitingList: true,
      partySize: bookingParams.pax || defaultDhpParams.pax,
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  const calendarCellRenderer = (data: CalendarRenderCellProps) => {
    if (!data.day) {
      return null;
    }
    if (loading) {
      return <SkeletonRect height={55} />;
    }
    const dateYYYYMMDD = formatForGraphQl(data.day);
    const availability = availabilities?.availabilities?.find((a) => a?.date === dateYYYYMMDD);
    const isDisabled = !availability;
    const offerAvailable = !!availability?.bestOffer;
    let nextStep = WizardStep.Result;

    if (!bookingParams.time || defaultDhpParams.date !== dateYYYYMMDD) {
      nextStep = WizardStep.Hour;
    } else if (!bookingParams.pax) {
      nextStep = WizardStep.Pax;
    }

    const isActive = bookingParams.date ? dateYYYYMMDD === bookingParams.date : dateYYYYMMDD === defaultDhpParams.date;
    
    return (
      <S.DateButton
        isActive={isActive}
        data-testid={`date-${dateYYYYMMDD}${isActive ? '-active' : ''}`}
        disabled={isDisabled}
        onClick={() => {
          if (nextStep === WizardStep.Result) {
            // Keep data when doing a "back" from the result page.
            handleSelectStep({ date: dateYYYYMMDD }, { replaceHistory: true });
          }
          handleSelectStep({ date: dateYYYYMMDD, step: nextStep });
        }}
      >
        {offerAvailable && <OfferIcon size="medium" data-testid={`offer-icon-${dateYYYYMMDD}`} />}
        <FormattedDate value={data.day} day="numeric" />
      </S.DateButton>
    );
  };

  return (
    <S.DateSelectorWrapper>
      <Calendar
        locale={locale}
        startOfWeek={startOfWeek}
        startDate={startDate}
        selectedDate={bookingParams.date ? dateStrToDate(bookingParams.date) : startDate}
        endDate={endDate}
        renderCell={calendarCellRenderer}
      />
      <AvailableOfferLegend />
    </S.DateSelectorWrapper>
  );
};
