import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { useBooking } from '@/hooks/useBooking';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import ReservationPreviewMeal from '@/assets/images/reservation-preview-meal.svg';
import * as S from './ReservationPreview.styles';

export const ReservationPreview: React.FC = () => {
  const intl = useIntl();
  const {
    bookingParams: { pax },
  } = useBooking();
  const paymentData = usePaymentContext();

  if (!pax) {
    throw new Error('Pax is not defined');
  }

  if (!paymentData.config?.prepayment) {
    return <div data-testid="no-reservation-preview"></div>;
  }

  const { amount, currency } = paymentData.config;
  return (
    <S.Container flexDirection="column" borderRadius="m" mt="xxl">
      <S.TitleBox pt="halfXxl" pb="halfL" px="m">
        <S.Title>
          <FormattedMessage
            id="tf_widget_creditCardGuarantee_reservationPreview_title"
            defaultMessage="Your reservation"
          />
        </S.Title>
      </S.TitleBox>
      <S.ContentFlex justifyContent="space-between" py="halfXxl" px="m">
        <Flex>
          <img src={ReservationPreviewMeal.src} alt="Reservation preview meal" height={78} width={78} />
          <Box ml="s">
            <S.TextBold>
              {`${pax}x ${intl.formatMessage({
                id: 'tf_widget_creditCardGuarantee_reservationPreview_content',
                defaultMessage: 'Reservation prepayment',
              })}`}
            </S.TextBold>
          </Box>
        </Flex>
        <Flex flexDirection="column" alignItems="end">
          <S.Text>
            <FormattedNumber value={amount * pax} style="currency" currency={currency} />
          </S.Text>
          <S.TextSmall mt="xxs">
            <FormattedMessage
              id="tf_widget_creditCardGuarantee_reservationPreview_pay_each"
              defaultMessage="{amountEach} each"
              values={{
                amountEach: <FormattedNumber value={amount} style="currency" currency={currency} />,
              }}
            />
          </S.TextSmall>
        </Flex>
      </S.ContentFlex>
      <Flex p="m" justifyContent="space-between" alignItems="center">
        <S.TextBold>
          <FormattedMessage
            id="tf_widget_creditCardGuarantee_reservationPreview_total_to_pay"
            defaultMessage="Total to pay"
          />
        </S.TextBold>
        <S.TextBold>
          <FormattedNumber value={amount * pax} style="currency" currency={currency} />
        </S.TextBold>
      </Flex>
    </S.Container>
  );
};
