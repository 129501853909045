import { Button } from '@/components/Button/Button';
import { Mention } from '@/components/Mention/Mention';
import { Message } from '@/components/Message/Message';
import { Checkbox } from '@/components/ReactHookForm/Checkbox';
import { Input } from '@/components/ReactHookForm/Input';
import { SingleRadio } from '@/components/ReactHookForm/Radio';
import { Select } from '@/components/ReactHookForm/Select';
import { ShatteredWineGlassIllustration } from '@/components/TechnicalError/ShatteredWineGlass.illustration';
import { Text } from '@/components/Text/Text';
import { getContrastRatioBetween } from '@/utils/theme/color-utils';
import { getFontColor, isLowColorContrast } from '@/utils/theme/theme-utils';
import { useTheme } from '@emotion/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconVoucher } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { PhoneSelect } from '../ReactHookForm/PhoneSelect';

export type FormType = {
  checkboxActive: boolean;
  checkboxDefault: boolean;
  checkboxDisabled: boolean;
  radioSelector: string;
  inputDefault: string;
  inputDisabled: string;
  selectDefault: string;
  selectDisabled: string;
  phone: string;
};

const getSchema = () =>
  z.object({
    checkboxActive: z.boolean().nullish(),
    checkboxDefault: z.boolean().nullish(),
    checkboxDisabled: z.boolean().nullish(),
    radioSelector: z.string().nullish(),
    inputDefault: z.string().nullish(),
    inputDisabled: z.string().nullish(),
    selectDefault: z.string().nullish(),
    selectDisabled: z.string().nullish(),
    phone: z.string().nullish(),
  });

export const PlaygroundStep = () => {
  const theme = useTheme();
  const { control, setValue } = useForm<FormType>({
    resolver: zodResolver(getSchema()),
    mode: 'onChange',
    defaultValues: {
      radioSelector: 'active',
    },
  });

  useEffect(() => {
    setValue('checkboxActive', true);
    setValue('radioSelector', 'active');
  }, [setValue]);

  const accentBackgroundContrast = Number(
    getContrastRatioBetween(theme.customization.colors.accent, theme.customization.colors.background).toFixed(2),
  );

  return (
    <>
      <p>Colors</p>
      <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap', textAlign: 'center' }}>
        <div
          style={{
            flex: 1,
            color: getFontColor(theme.customization.colors.accent),
            backgroundColor: theme.customization.colors.accent,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getFontColor(theme.customization.colors.background),
            padding: 5,
          }}
        >
          ACCENT
        </div>
        <div
          style={{
            flex: 1,
            color: getFontColor(theme.customization.colors.background),
            backgroundColor: theme.customization.colors.background,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: getFontColor(theme.customization.colors.background),
            padding: 5,
          }}
        >
          BACKGROUND
        </div>
      </div>
      <div>
        <Text variant="micro">
          Accent/background contrast: {accentBackgroundContrast}
          {' - '}
          Color contrast: {accentBackgroundContrast < 3 ? `Bad` : `Good`}
          {' - '}
          Text contrast: {accentBackgroundContrast < 4.5 ? `Bad` : `Good`}
        </Text>
        {isLowColorContrast(theme.customization.colors.accent, '#FFFFFF') && (
          <Text variant="micro">Accent near WHITE</Text>
        )}
        {isLowColorContrast(theme.customization.colors.accent, '#000000') && (
          <Text variant="micro">Accent near BLACK</Text>
        )}
      </div>
      <p>Buttons</p>
      <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
        <Button variant="primary" disabled={false}>
          Primary
        </Button>
        <Button variant="secondary" disabled={false}>
          Secondary
        </Button>
        <Button variant="ghost" disabled={false}>
          Ghost
        </Button>
        <Button variant="compact" disabled={false}>
          Compact
        </Button>
      </div>
      <div style={{ display: 'flex', gap: 5, marginTop: 5, flexWrap: 'wrap' }}>
        <Button variant="primary" isActive={true}>
          Primary
        </Button>
        <Button variant="secondary" isActive={true}>
          Secondary
        </Button>
        <Button variant="ghost" isActive={true}>
          Ghost
        </Button>
        <Button variant="compact" isActive={true}>
          Compact
        </Button>
      </div>
      <div style={{ display: 'flex', gap: 5, marginTop: 5, flexWrap: 'wrap' }}>
        <Button variant="primary" disabled={true}>
          Primary
        </Button>
        <Button variant="secondary" disabled={true}>
          Secondary
        </Button>
        <Button variant="ghost" disabled={true}>
          Ghost
        </Button>
        <Button variant="compact" disabled={true}>
          Compact
        </Button>
      </div>
      <p>Checkbox</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <Checkbox
          control={control}
          name="checkboxActive"
          label="active"
          prefix="contact-information"
          disabled={false}
        />
        <Checkbox
          control={control}
          name="checkboxDefault"
          label="default"
          prefix="contact-information"
          disabled={false}
        />
        <Checkbox
          control={control}
          name="checkboxDisabled"
          label="disabled"
          prefix="contact-information-disabled"
          disabled={true}
        />
      </div>
      <p>Radio</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <SingleRadio id="radioSelectorActive" name="radioSelector" value="active" checked={true} readOnly>
          radio active
        </SingleRadio>
        <SingleRadio id="radioSelectorDefault" name="radioSelector" value="default">
          radio default
        </SingleRadio>
        <SingleRadio id="radioSelectorDisabled" name="radioSelector" value="disabled" disabled={true}>
          radio disabled
        </SingleRadio>
      </div>
      <p>Input</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <div style={{ flex: 1 }}>
          <Input
            id="inputDefault"
            aria-labelledby="inputDefault"
            control={control}
            name="inputDefault"
            label="First name"
            placeholder="Enter your first name"
            type="text"
            prefix="input-default"
            required
          />
        </div>
        <div style={{ flex: 1 }}>
          <Input
            id="inputDisabled"
            aria-labelledby="inputDisabled"
            control={control}
            name="inputDisabled"
            label="First name disabled"
            placeholder="Enter your first name disabled"
            type="text"
            prefix="input-disabled"
            required
            disabled={true}
          />
        </div>
      </div>
      <p>Select</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <div style={{ flex: 1 }}>
          <Select
            id="selectDefault"
            key="selectDefault"
            control={control}
            name="selectDefault"
            options={[
              {
                value: true,
                label: 'OK',
              },
              {
                value: false,
                label: 'Pas OK',
              },
            ]}
            isDisabled={false}
            required={true}
            label="Select"
            data-id="selectDefault"
            canHaveLegend={false}
            placeholder="Select something"
            prefix="select-default"
            flexGrow={true}
          />
        </div>
        <div style={{ flex: 1 }}>
          <Select
            id="selectDisabled"
            key="selectDisabled"
            control={control}
            name="selectDisabled"
            options={[
              {
                value: true,
                label: 'OK disabled',
              },
              {
                value: false,
                label: 'Pas OK disabled',
              },
            ]}
            isDisabled={true}
            required={true}
            label="Select disabled"
            data-id="selectDisabled"
            canHaveLegend={false}
            placeholder="Select something disabled"
            prefix="select-disabled"
            flexGrow={true}
          />
        </div>
      </div>
      <div style={{ display: 'flex', gap: 5 }}>
        <div style={{ flex: 1 }}>
          <PhoneSelect
            control={control as any /* eslint-disable-line @typescript-eslint/no-explicit-any*/}
            name="phone"
            countryCode="fr"
            label="Select phone"
            required
            prefix="contact-information"
          />
        </div>
        <div style={{ flex: 1 }}>
          <Input
            id="inputDefault"
            aria-labelledby="inputDefault"
            control={control}
            name="inputDefault"
            label="Dummy input"
            placeholder="Just to check alignment"
            type="text"
            prefix="input-default"
            required
          />
        </div>
      </div>
      <p>Message</p>
      <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
        <Message variant="information">Information</Message>
        <Message variant="warning">Warning</Message>
        <Message variant="alert">Alert</Message>
        <Message variant="outline" Icon={IconVoucher}>
          Outline IconVoucher
        </Message>
      </div>
      <p>Mention</p>
      <div style={{ display: 'flex', gap: 5 }}>
        <Mention isActive={true} title="Mention title" content="Mention content" />
      </div>
      <p>SVG</p>
      <div style={{ display: 'flex' }}>
        <ShatteredWineGlassIllustration />
      </div>
    </>
  );
};
