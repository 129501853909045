import { IconArrowLeft } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { useIntl } from 'react-intl';
import { ErrorLayout } from '@/components/ErrorLayout/ErrorLayout';
import { RestaurantInMagIllustration } from '@/components/NoAvailabilities/RestaurantInMag.illustration';
import { useBooking } from '@/hooks/useBooking';
import { WizardStep } from '@/types/WizardBooking';

export const ErrorStep = () => {
  const intl = useIntl();
  const { handleSelectStep } = useBooking();

  return (
    <ErrorLayout
      Illustration={RestaurantInMagIllustration}
      title={intl.formatMessage({
        id: 'tf_widget_error_noAvailability_title',
        defaultMessage: 'Your reservation is no longer available.',
      })}
      message={intl.formatMessage({
        id: 'tf_widget_error_noAvailability_message',
        defaultMessage:
          'Sorry, seems like that date or number of guests is no longer up for reservation, please return to select your reservation information again.',
      })}
      action={{
        icon: <IconArrowLeft />,
        label: intl.formatMessage({
          id: 'tf_widget_noAvailability_back',
          defaultMessage: 'Go back to previous step',
        }),
        handleAction: () => handleSelectStep({ step: WizardStep.Result }, { onlyKeepDHP: true }),
      }}
    />
  );
};
