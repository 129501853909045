import { useState } from 'react';

export type CardElementsValid = {
  cardNumber: boolean;
  expirationDate: boolean;
  securityCode: boolean;
};
export const useCardElementsState = () => {
  const [cardElementsLoaded, setCardElementsLoaded] = useState<{
    cardNumber: boolean;
    expirationDate: boolean;
    securityCode: boolean;
  }>({
    cardNumber: false,
    expirationDate: false,
    securityCode: false,
  });
  const [cardElementsValid, setCardElementsValid] = useState<CardElementsValid>({
    cardNumber: false,
    expirationDate: false,
    securityCode: false,
  });
  return {
    cardElementsLoaded,
    setCardElementsLoaded,
    cardElementsValid,
    setCardElementsValid,
  };
};
