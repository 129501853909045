import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';
import { useBooking } from '@/hooks/useBooking';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import * as S from './Conditions.styles';

export const Conditions = () => {
  const paymentData = usePaymentContext();
  const {
    bookingParams: { pax },
  } = useBooking();

  if (!paymentData.config || !pax) {
    return null;
  }

  const { currency, minimumCancellationHours, amount } = paymentData.config;
  const totalAmount = amount * pax;

  if (paymentData.config.imprint) {
    return (
      <S.ConditionsContainer>
        <S.TermsWrapper>
          <S.Terms>
            <FormattedMessage
              id="tf_widget_creditCardGuarantee__term_warning_imprint"
              defaultMessage="By confirming your reservation, you agree to the Credit Card Imprint Conditions indicated below. "
            />
          </S.Terms>
          <S.TermsFee>
            <FormattedMessage
              id="tf_widget_creditCardGuarantee__term_no_fee_confirmation"
              defaultMessage="Instant confirmation. No reservation fee."
            />
          </S.TermsFee>
        </S.TermsWrapper>
        <S.TermsPrePaymentConditions>
          <FormattedMessage
            id="tf_widget_creditCardGuarantee_imprint_conditions_title"
            defaultMessage="Credit Card Imprint Conditions"
          />
        </S.TermsPrePaymentConditions>
        <S.Terms as={'span'}>
          <FormattedMessage
            id="tf_widget_creditCardGuarantee_term_imprint_conditions_with_link"
            defaultMessage="<li>The credit card imprint functionality is
              operated by the restaurant, NOT by TheFork. To the fullest extent permitted by law, TheFork will have no
              liability or responsibility for any amounts you are charged by the restaurant, or any related disputes. </li>
              <li>Any credit card imprint or charge on your card is between you and the restaurant directly, and is processed by <stripeLink>Stripe</stripeLink>. TheFork is not a part of any such transaction. </li>
              <li>You must provide your card details to secure your reservation. Your card will not be charged now. </li>
              <li>You may cancel or change your reservation (subject to availability) at no cost up to <minimumCancellationHours></minimumCancellationHours> hours before the time of your reservation.
               If you cancel or make changes after that <minimumCancellationHours></minimumCancellationHours> hour deadline, or are a no-show, the restaurant may charge your card <totalAmountAndCurrency></totalAmountAndCurrency>. </li>
               <li>You authorize the restaurant to send instructions through TheFork to your card issuer to take payments from your card in the above circumstances.</li>
               <li>The restaurant may cancel any reservation it reasonably believes is fraudulent, abusive or illegal.</li>
               <li>Contact the restaurant if you do not receive confirmation of your reservation, or if any details in the confirmation are incorrect. </li>"
            values={{
              stripeLink: (chunk) => (
                <b>
                  <a href={'https://stripe.com/en-fr/about'} rel="noreferrer" target="_blank">
                    {chunk}
                  </a>
                </b>
              ),
              li: (chunk) => <li>{chunk}</li>,
              totalAmountAndCurrency: () => (
                <FormattedNumber value={totalAmount} style="currency" currency={currency} />
              ),
              minimumCancellationHours: () => minimumCancellationHours,
            }}
          />
        </S.Terms>
      </S.ConditionsContainer>
    );
  }

  if (paymentData.config.prepayment) {
    return (
      <S.ConditionsContainer>
        <S.TermsWrapper>
          <S.Terms>
            <FormattedMessage
              id="tf_widget_creditCardGuarantee__term_warning"
              defaultMessage="By confirming your reservation, you agree to the Pre-payment Conditions indicated below."
            />
          </S.Terms>
          <S.TermsFee>
            <FormattedMessage
              id="tf_widget_creditCardGuarantee__term_no_fee_confirmation"
              defaultMessage="Instant confirmation. No reservation fee."
            />
          </S.TermsFee>
        </S.TermsWrapper>
        <S.TermsPrePaymentConditions>
          <FormattedMessage
            id="tf_widget_creditCardGuarantee__pre_payment_conditions_title"
            defaultMessage="Pre-payment Conditions"
          />
        </S.TermsPrePaymentConditions>
        <S.Terms as={'span'}>
          {paymentData.config.paymentProvider.isAutoRefundEnabled ? (
            <FormattedMessage
              id="tf_widget_creditCardGuarantee_term_pre_payment_conditions_stripe_custom_with_link"
              defaultMessage="<bold>A prepayment of <totalAmountAndCurrency></totalAmountAndCurrency> per person is required </bold><br></br><br></br><li>The pre-payment functionality is
                    operated by the restaurant, NOT by TheFork. To the fullest extent permitted by law, TheFork will have no
                    liability or responsibility for any amounts you pay to, or are charged by, the restaurant, or any related
                    disputes.</li><li>All payments are between you and the restaurant directly, and are processed by <stripeLink>Stripe</stripeLink>. TheFork is not
                    a party to the transaction.</li><li>To secure your reservation, your card will be charged now for the full amount of
                    your reservation.</li><li>To the fullest extent permitted by law, you agree that your prepaid reservation is
                    NON-EXCHANGEABLE and NON-TRANSFERABLE, except at the discretion of the restaurant (contact the restaurant for
                    more information).</li><li>If you cancel or make changes to your reservation (subject to availability) up to
                    <minimumCancellationHours></minimumCancellationHours> hours before the time of your reservation, your card will be refunded. If you cancel or make
                    changes to your reservation after that <minimumCancellationHours></minimumCancellationHours> hour deadline, or are a no-show, your card will not be
                    refunded.</li><li>The restaurant may cancel any reservation it reasonably believes that is fraudulent, abusive or
                    illegal.</li><li>Please contact the restaurant if you do not receive confirmation of your reservation, or if any details
                    in the confirmation are incorrect.</li>"
              values={{
                bold: (chunk) => <b>{chunk}</b>,
                stripeLink: (chunk) => (
                  <b>
                    <a href={'https://stripe.com/en-fr/about'} rel="noreferrer" target="_blank">
                      {chunk}
                    </a>
                  </b>
                ),
                br: () => <br />,
                li: (chunk) => <li>{chunk}</li>,
                totalAmountAndCurrency: () => <FormattedNumber value={amount} style="currency" currency={currency} />,
                minimumCancellationHours: () => minimumCancellationHours,
              }}
            />
          ) : (
            <FormattedMessage
              id="tf_widget_creditCardGuarantee_term_pre_payment_conditions_stripe_standard_with_link"
              defaultMessage="<bold>A prepayment of <totalAmountAndCurrency></totalAmountAndCurrency> per person is required </bold><br></br><br></br> <li>The pre-payment functionality is
                    operated by the restaurant, NOT by TheFork. To the fullest extent permitted by law, TheFork will have no
                    liability or responsibility for any amounts you pay to, or are charged by, the restaurant, or any related
                    disputes.</li><li>All payments are between you and the restaurant directly, and are processed by <stripeLink>Stripe</stripeLink>. TheFork is not
                    a party to the transaction.</li><li>To secure your reservation, your card will be charged now for the full amount of
                    your reservation.</li><li>To the fullest extent permitted by law, you agree that your prepaid reservation is FINAL, NON-REFUNDABLE, NON-EXCHANGEABLE and NON-TRANSFERABLE, except at the discretion of the restaurant (contact the restaurant for
                    more information).</li><li>The restaurant may cancel any reservation it reasonably believes that is fraudulent, abusive or
                    illegal.</li><li>Please contact the restaurant if you do not receive confirmation of your reservation, or if any details
                    in the confirmation are incorrect.</li>"
              values={{
                bold: (chunk) => <b>{chunk}</b>,
                stripeLink: (chunk) => (
                  <b>
                    <a href={'https://stripe.com/en-fr/about'} rel="noreferrer" target="_blank">
                      {chunk}
                    </a>
                  </b>
                ),
                br: () => <br />,
                li: (chunk) => <li>{chunk}</li>,
                totalAmountAndCurrency: () => <FormattedNumber value={amount} style="currency" currency={currency} />,
              }}
            />
          )}
        </S.Terms>
      </S.ConditionsContainer>
    );
  }

  return <div data-testid="ccg-no-conditions"></div>;
};
