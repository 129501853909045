import { FormattedMessage } from 'react-intl';
import { OfferIcon } from '../OfferIcon/OfferIcon';
import * as S from './AvailableOfferLegend.styles';

export const AvailableOfferLegend = () => {
  return (
    <S.Container alignItems="center" data-testid="available-offer-legend">
      <S.TimeslotContainer>
        <OfferIcon size="small" />
      </S.TimeslotContainer>
      <FormattedMessage
        tagName={S.Message}
        id="tf_widget_available_offer_legend"
        defaultMessage="Menu or Experience available"
      />
    </S.Container>
  );
};
