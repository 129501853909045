import React from 'react';
import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Text from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';
import CreditcardCross from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons/CreditcardCross';
import { FOR_DESKTOP_ONLY } from '@/utils/emotion-utils';

const CONTENT_WIDTH = px2rem(490);
const ICON_WIDTH = px2rem(60);

export const ErrorModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${FOR_DESKTOP_ONLY} {
    width: ${CONTENT_WIDTH};
  }
`;

export const ErrorModalIcon = styled(CreditcardCross)`
  color: ${({ theme }) => theme.customization.colors.accent};
  font-size: ${ICON_WIDTH};
`;

export const ErrorModalTitle = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <Text {...props} textAlign="center" variant="bodyRegular" mt="l" mb="s" />;
};

export const ErrorModalText = (props: React.HTMLAttributes<HTMLParagraphElement>) => {
  return <Text {...props} textAlign="center" variant="bodyLight" mt="0" mb="xl" />;
};

export const ErrorModalButtonContainer = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.space.xxl} ${({ theme }) => theme.space.m} ${({ theme }) => theme.space.xxl};
  ${FOR_DESKTOP_ONLY} {
    padding: 0 0 ${({ theme }) => theme.space.m} 0;
  }
`;
