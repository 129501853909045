import { Button } from '@/components/Button/Button';
import { BELOW_MOBILE_LANDSCAPE } from '@/utils/emotion-utils';
import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import Box from '@lafourchette/react-chili/dist/cjs/components/Atoms/Box';
import { ComponentProps } from 'react';

export const DateButton = styled((props: ComponentProps<typeof Button>) => <Button variant="secondary" {...props} />)`
  width: 100%;
  height: ${px2rem(55)};
  padding: 0;
`;

export const DateSelectorWrapper = styled((props: ComponentProps<typeof Box>) => <Box my="xxl" mx="m" {...props} />)`
  width: 100%;
  margin: 0 auto;
  ${BELOW_MOBILE_LANDSCAPE} {
    margin-left: auto;
    margin-right: auto;
  }
  height: ${px2rem(450)};
  overflow: hidden;
`;
