import { Text } from '@/components/Text/Text';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';

export const Card = styled.article`
  display: flex;
  width: 100%;
  height: fit-content;
  min-height: 5rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: flex-start;
  outline: none;
  cursor: pointer;
  padding: ${({ theme }) => `${theme.space.halfL} ${theme.space.halfL} 0 ${theme.space.halfL}`};
  border-radius: ${({ theme }) => theme.space.s};
  background-color: ${({ theme }) => theme.customization.colors.cta.secondary};
  border: solid 1px ${({ theme }) => theme.customization.colors.text.onFields};
  transition: background-color ${({ theme }) => theme.duration.m};

  &:hover {
    background-color: ${({ theme }) => theme.customization.colors.cta.secondary.hover};
    border: solid 1px ${({ theme }) => theme.customization.colors.text.onCta.secondary.hover};
    transition: background-color 0s;
  }

  &:active:not(:has(.read-more-btn:active)) {
    background-color: ${({ theme }) => theme.customization.colors.cta.secondary.pressed};
  }
`;

export const SelectCardButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: column;
  outline: none;
`;

export const CardHeader = styled((props: ComponentProps<typeof Flex>) => (
  <Flex justifyContent="space-between" alignItems="center" color="inherit" mb="halfL" {...props} />
))`
  box-sizing: border-box;
  width: 100%;
`;

export const CardTitleContainer = styled((props: ComponentProps<typeof Flex>) => (
  <Flex alignItems="center" {...props} />
))``;

export const CardTitle = styled((props: ComponentProps<typeof Text>) => (
  <Text variant="smallRegular" color="inherit" m={0} {...props} />
))`
  flex-grow: 1;
  font-weight: bold;
  color: ${({ theme }) => theme.customization.colors.text.onFields};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  text-align: left;
`;

export const CardContent = styled((props: ComponentProps<typeof Text>) => (
  <Text display="block" as="span" variant="small" color="inherit" mb="halfL" {...props} />
))`
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.customization.colors.text.onFields};
  font-family: ${({ theme }) => theme.customization.fonts.body};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const CardBottomContent = styled(CardContent)`
  margin: 0;
`;

export const CreditCardText = styled(Text)`
  display: flex;
  align-items: center;
  column-gap: ${({ theme }) => theme.space.xs};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  margin-bottom: ${({ theme }) => theme.space.xs};
`;

export const ValidityText = styled(Text)`
  background-color: ${({ theme }) => theme.customization.colors.cta.ghost.hover};
  color: ${({ theme }) => theme.customization.colors.text.onCta.ghost.hover};
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.s}`};
  border-radius: ${({ theme }) => theme.radii.m};
  margin: 0 0 ${({ theme }) => theme.space.halfL};
`;

export const MoreInformation = styled((props: ComponentProps<typeof Text>) => (
  <Text variant="smallRegular" m="0" {...props} />
))`
  font-weight: ${({ theme }) => theme.fontWeights.l};
  font-family: ${({ theme }) => theme.customization.fonts.body};
`;

export const FooterCard = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => `calc(100% + ${theme.space.l})`};
  background: none;
  border-top: solid 1px ${({ theme }) => theme.customization.colors.border.line};
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  padding: ${({ theme }) => theme.space.halfL};
  margin: 0 -${({ theme }) => theme.space.halfL};
  color: ${({ theme }) => theme.customization.colors.text.onFields};
  overflow: hidden;
  transition: background-color ${({ theme }) => theme.duration.m};
  flex-grow: 1;

  &:hover {
    background-color: ${({ theme }) => theme.customization.colors.cta.secondary.pressed};
    transition: background-color 0s;
  }
`;

export const FooterCardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  background: none;
  align-items: center;
  min-height: 30px;
`;
