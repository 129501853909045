import {
  IconChevronBottom,
  IconChevronTop,
  IconCreditcard,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import React, { ReactNode, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { AvailabilityPeriod } from '@/graphql/types.generated';
import { AnimatePresence, motion } from 'framer-motion';
import * as S from './OfferCard.styles';

export type OfferCardProps = {
  title: string;
  children: ReactNode;
  onSelect: () => void;
  price?: number;
  currency?: string;
  hasReadMore?: boolean;
  isCCRequired?: boolean;
  validityPeriod?: AvailabilityPeriod;
  dataTestId?: string;
};

export const OfferCard: React.FC<OfferCardProps> = ({
  title,
  onSelect,
  price,
  currency,
  hasReadMore,
  children,
  isCCRequired,
  validityPeriod,
  dataTestId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const intl = useIntl();

  const isOpenText = isOpen
    ? intl.formatMessage({
        id: 'tf_widget_offer_read_less',
        defaultMessage: 'Read less',
      })
    : intl.formatMessage({
        id: 'tf_widget_offer_read_more',
        defaultMessage: 'Read more',
      });

  return (
    <S.Card>
      <S.SelectCardButton onClick={onSelect} data-testid={dataTestId}>
        <S.CardHeader>
          <S.CardTitleContainer>
            <S.CardTitle>{title}</S.CardTitle>
          </S.CardTitleContainer>
        </S.CardHeader>
        {(price != null || isCCRequired) && (
          <S.CardContent>
            {price != null && (
              <>
                <FormattedNumber value={price} style="currency" currency={currency} />{' '}
                <FormattedMessage id="tf_widget_offer_per_guest" defaultMessage="per guest" />
              </>
            )}
            {isCCRequired && (
              <S.CreditCardText>
                <IconCreditcard />
                <FormattedMessage id="tf_widget_offer_credit_card_required" defaultMessage="Credit card required" />
              </S.CreditCardText>
            )}
          </S.CardContent>
        )}
        {validityPeriod?.end && (
          <S.ValidityText variant="smallRegular">
            <FormattedMessage
              id="tf_widget_offer_validity_period"
              defaultMessage="Available from {start} to {end}"
              values={{
                start: <FormattedDate value={validityPeriod.start} day="numeric" month="long" />,
                end: <FormattedDate value={validityPeriod.end} day="numeric" month="long" />,
              }}
            />
          </S.ValidityText>
        )}
        {!hasReadMore && <S.CardContent>{children}</S.CardContent>}
      </S.SelectCardButton>

      {hasReadMore && (
        <AnimatePresence>
          <S.FooterCard className="read-more-btn" onClick={() => setIsOpen(!isOpen)}>
            <S.FooterCardHeader>
              <S.MoreInformation>{isOpenText}</S.MoreInformation>
              {isOpen ? <IconChevronTop /> : <IconChevronBottom />}
            </S.FooterCardHeader>
            <motion.div
              initial={{ height: 0 }}
              animate={{ height: isOpen ? 'auto' : 0 }}
              exit={{ height: 0 }}
              transition={{ duration: 0.25 }}
            >
              <S.CardBottomContent>{children}</S.CardBottomContent>
            </motion.div>
          </S.FooterCard>
        </AnimatePresence>
      )}
    </S.Card>
  );
};
