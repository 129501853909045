import { FOR_DESKTOP_ONLY, FOR_NORMAL_SIZE } from '@/utils/emotion-utils';
import styled from '@emotion/styled';
import Grid from '@lafourchette/react-chili/dist/cjs/components/Atoms/Grid';
import { ComponentProps } from 'react';

export const GridContainer = styled((props: ComponentProps<typeof Grid>) => (
  <Grid gridTemplateColumns="repeat(auto-fit, minmax(6rem, 1fr));" {...props} />
))`
  grid-gap: ${({ theme }) => theme.space.s};
  grid-template-columns: 1fr 1fr;
  ${FOR_NORMAL_SIZE} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  ${FOR_DESKTOP_ONLY} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;
