import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { WidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import getConfig from 'next/config';
import Script from 'next/script';

const event = (eventName: string) => {
  window.fbq('track', eventName);
};

const handleRouteChange = (e: string) => {
  if (e.includes('/success')) {
    event('Schedule');
  } else {
    event('PageView');
  }
};

const useFacebookIntegration = (config: WidgetConfig) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();

  const isFacebookOrigin = useMemo(() => {
    return router.query.origin && router.query.origin === 'facebook';
  }, [router.query]);

  useEffect(() => {
    if (!config.facebookIntegration || !isFacebookOrigin) return;

    event('PageView');

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, config.facebookIntegration, isFacebookOrigin]);

  return {
    openGraph: (
      <>
        <meta property="og:title" content={config.restaurant?.name || ''} />
        <meta property="og:site_name" content={config.restaurant?.name || ''} />
        <meta property="og:description" content="The Fork" />
        <meta property="og:image" content="/logo-horizontal.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://module.lafourchette.com" />
        <meta property="fb:app_id" content={publicRuntimeConfig.facebookAppId} />
      </>
    ),
    pixelScript: config.facebookIntegration && isFacebookOrigin && (
      <>
        <Script
          id="fb-pixel"
          dangerouslySetInnerHTML={{
            __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${config.facebookIntegration.pixelId});
          `,
          }}
        />
        <noscript>
          <img
            alt="fbpx"
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${config.facebookIntegration.pixelId}&ev=PageView&noscript=1`}
          />
        </noscript>
      </>
    ),
  };
};

export default useFacebookIntegration;
