import { TimeslotCard } from '@/components/TimeslotCard/TimeslotCard';
import { SisterRestaurant, TimeslotFragment } from '@/graphql/types.generated';
import { useState } from 'react';
import { WizardStep } from '@/types/WizardBooking';
import { FormattedDate, FormattedList, FormattedMessage, FormattedNumber } from 'react-intl';
import { googleMapsUrl } from '@/utils/google/google-utils';
import { formatCloudinaryImageUrl } from '@/utils/format-cloudinary-image-utils';
import { Divider } from '@/components/Divider/Divider';
import { dateStrToDate, dateStrToDateNoTZ } from '@/utils/date-utils';
import {
  IconRestaurantCutlery,
  IconCash,
  IconLocationPin,
} from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import BlankSVG from '@/assets/images/blank.svg';
import * as S from './SisterRestaurantCard.styles';
import { useUrlQueryParams } from '@/hooks/useUrlQueryParams';

export type SisterRestaurantCardProps = {
  sisterRestaurant: SisterRestaurant;
  originRestaurantUuid: string;
  currentRestaurantName: string;
  pax: number;
  date: string;
  dataTestId?: string;
};

export const SisterRestaurantCard = ({
  sisterRestaurant,
  originRestaurantUuid,
  currentRestaurantName,
  pax,
  date,
  dataTestId,
}: SisterRestaurantCardProps) => {
  const [imageSrc, setImageSrc] = useState<string | null>(sisterRestaurant.coverPhotoUrl || null);
  const { appendCustomQueryParamsToUrl } = useUrlQueryParams();

  const goToSisterTimeslots = (timeslot: TimeslotFragment) => {
    const sisterUrl = new URL(window.location.href);
    sisterUrl.search = ''; // Clear search parameters
    sisterUrl.pathname = `/${sisterRestaurant.restaurantUuid}`;
    sisterUrl.searchParams.append('pax', pax.toString());
    sisterUrl.searchParams.append('date', date);
    sisterUrl.searchParams.append('time', timeslot.time.toString());
    sisterUrl.searchParams.append('step', WizardStep.Result);
    sisterUrl.searchParams.append('crossSellUuid', originRestaurantUuid);

    window.open(appendCustomQueryParamsToUrl(sisterUrl), '_blank');
  };

  return (
    <S.Card data-testid={dataTestId}>
      <S.ImageContainer>
        <img
          src={imageSrc ? formatCloudinaryImageUrl({ url: imageSrc, width: '342', height: '120' }) : BlankSVG.src}
          alt={sisterRestaurant.name}
          onError={() => setImageSrc(null)}
          style={{ width: '100%', objectFit: 'cover' }}
        />
      </S.ImageContainer>
      <S.InfoContainer>
        <S.Title variant="title3" data-testid="sister-restaurant-card-name">
          {sisterRestaurant.name}
        </S.Title>
        {sisterRestaurant.distanceInMeters != null && (
          <Flex gap="s" data-testid="sister-restaurant-card-address">
            <Flex>
              <IconLocationPin />
            </Flex>
            <S.AddressContainer>
              <S.Link
                href={googleMapsUrl({
                  address: sisterRestaurant.address,
                  name: sisterRestaurant.name,
                })}
                target="_blank"
              >
                <span>
                  {sisterRestaurant.address.address}, {sisterRestaurant.address.zipCode} {sisterRestaurant.address.city}
                </span>
              </S.Link>
              <S.Content variant="smallLight">
                (
                <FormattedMessage
                  id="tf_widget_sister_restaurant_card_distance"
                  defaultMessage="{distance} km from"
                  values={{ distance: Math.round(sisterRestaurant.distanceInMeters / 100) / 10 }}
                />{' '}
                {currentRestaurantName})
              </S.Content>
            </S.AddressContainer>
          </Flex>
        )}
        {sisterRestaurant.cuisine && sisterRestaurant.cuisine?.length > 0 && (
          <Flex gap="s" data-testid="sister-restaurant-card-cuisine-type">
            <Flex>
              <IconRestaurantCutlery />
            </Flex>
            <S.EllipsisContent variant="smallLight">
              <FormattedList value={sisterRestaurant.cuisine?.map((cuisine) => cuisine.label)} type="unit" />
            </S.EllipsisContent>
          </Flex>
        )}
        {sisterRestaurant.averagePrice?.value && sisterRestaurant.averagePrice.currency && (
          <Flex gap="s" data-testid="sister-restaurant-card-average-price">
            <Flex>
              <IconCash />
            </Flex>
            <S.Content variant="smallLight">
              <FormattedMessage
                id="tf_widget_sister_restaurant_card_average_price"
                defaultMessage="{averagePrice} average price"
                values={{
                  averagePrice: (
                    <span>
                      <FormattedNumber
                        value={sisterRestaurant.averagePrice.value}
                        style="currency"
                        currency={sisterRestaurant.averagePrice.currency}
                      />
                    </span>
                  ),
                }}
              />
            </S.Content>
          </Flex>
        )}
      </S.InfoContainer>

      <Divider />

      <S.BottomSide>
        <S.ContentDate variant="smallLight">
          <FormattedDate value={dateStrToDateNoTZ(date)} weekday="long" day="numeric" month="long" />
        </S.ContentDate>
        <S.TimeslotsList>
          {sisterRestaurant.timeslots?.slice(0, 8).map((timeslot, index) => {
            const offerAvailable = timeslot.offers?.length > 0;
            return (
              <TimeslotCard
                key={index}
                handleSelectStep={() => goToSisterTimeslots(timeslot)}
                date={dateStrToDate(timeslot.datetime)}
                dataTestId={`sister-card-time-slot-${index}`}
                offerAvailable={offerAvailable}
              />
            );
          })}
        </S.TimeslotsList>
      </S.BottomSide>
    </S.Card>
  );
};
