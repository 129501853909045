import { ButtonLayout as ButtonLayoutComponent } from '@/components/ButtonLayout/ButtonLayout';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';
import styled from '@emotion/styled';

export const MainContainer = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.space.xl};
`;

export const ButtonLayout = styled(ButtonLayoutComponent)`
  gap: 12px;
  margin-top: 0;
`;

export const SuccessFooter = styled.footer`
  width: 100%;
  text-align: center;
  padding: ${({ theme }) => theme.space.s};
  margin-top: ${({ theme }) => theme.space.xxl};
`;
