import styled from '@emotion/styled';
import { Text } from '@/components/Text/Text';
import { Heading } from '@/components/Heading/Heading';
import Flex from '@lafourchette/react-chili/dist/cjs/components/Atoms/Flex';

export const Card = styled.div`
  border-radius: ${({ theme }) => theme.radii.m};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.customization.colors.grey300};
  overflow: hidden;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space.m};
  padding-bottom: ${({ theme }) => theme.space.s};
  gap: ${({ theme }) => theme.space.xs};
`;

export const ImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 120px;
`;

export const Title = styled(Heading)`
  margin: 0;
  font-weight: 400;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

export const Content = styled(Text)`
  margin: 0;

  & > span {
    font-weight: bold;
  }
`;

export const EllipsisContent = styled(Content)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.xxs};
`;

export const Link = styled.a`
  display: block;
  font-size: ${({ theme }) => theme.fontSizes.xs};
  font-weight: 400;
  line-height: 18px;
  text-decoration: underline;
`;

export const ContentDate = styled(Content)`
  text-transform: capitalize;
  cursor: default;
  margin-bottom: ${({ theme }) => theme.space.s};
`;

export const BottomSide = styled.div`
  padding: ${({ theme }) => theme.space.m};
`;

export const TimeslotsList = styled(Flex)`
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.space.s};
`;
