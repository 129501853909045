import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementLocale } from '@stripe/stripe-js';
import { Loader } from '@/components/Loader/Loader';
import { useRouter } from 'next/router';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';

export const StripeCreditCardWrapper: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const paymentData = usePaymentContext();
  const { locale } = useRouter();

  if (!paymentData.config?.paymentProvider.authData) {
    return null;
  }

  const {
    config: {
      paymentProvider: { authData: stripeAuth },
    },
  } = paymentData;

  const { publishableKey, paymentGuaranteeIntent } = stripeAuth;

  const { stripeAccountId, stripeIntentClientSecret } = paymentGuaranteeIntent.stripeAuthData || {};

  if (!publishableKey) {
    return <Loader />;
  }

  const stripePromise = loadStripe(publishableKey, {
    stripeAccount: stripeAccountId || undefined,
    locale: (locale as StripeElementLocale) || 'auto',
  });

  const appearance = {
    variables: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFamily: 'RalewayX, verdana, sans-serif',
    },
  };

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: stripeIntentClientSecret || '',
        appearance,
      }}
    >
      {children}
    </Elements>
  );
};
