type SetTrackingDataParams = Partial<{
  crossSellUuid: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
}>;

export const setTrackingData = (params: SetTrackingDataParams) => {
  const trackingData = [];

  for (const trackingDataKey of ['crossSellUuid', 'utm_source', 'utm_medium', 'utm_campaign'] as const) {
    if (params[trackingDataKey]) {
      trackingData.push({
        key: trackingDataKey,
        value: String(params[trackingDataKey]),
      });
    }
  }

  return trackingData;
};
