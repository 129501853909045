import { Heading } from '@/components/Heading/Heading';
import { ButtonGrid } from '@/components/ButtonGrid/ButtonGrid';
import { MainContentBoxMaxWidth } from '@/components/WizardLayout/WizardLayout.styles';
import { Button } from '@/components/Button/Button';
import styled from '@emotion/styled';

export const TopContainer = styled.div`
  max-width: ${MainContentBoxMaxWidth.S};
  margin: 0 auto;
  display: grid;
  gap: ${({ theme }) => theme.space.xl};
`;

export const Title = styled(Heading)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: ${({ theme }) => theme.space.s};
  margin: 0;
`;

export const TitleIconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const Subtitle = styled(Heading)`
  text-align: center;
  margin: 0 0 ${({ theme }) => theme.space.halfL};
`;

export const StyledButtonGrid = styled(ButtonGrid)`
  padding: 0;
  margin: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.halfL};
`;

export const ButtonFindDate = styled(Button)`
  min-width: 80%;
`;

export const BottomDividerDiv = styled.div`
  max-width: ${MainContentBoxMaxWidth.S};
  margin: auto;
  padding: ${({ theme }) => theme.space.xl} 0;
`;
