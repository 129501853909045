import { Button } from '@/components/Button/Button';
import styled from '@emotion/styled';
import { px2rem } from '@lafourchette/design-system-theming/utils';
import { IconChevronLeft, IconChevronRight } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import Text from '@lafourchette/react-chili/dist/cjs/components/Atoms/Text';
import { ComponentProps } from 'react';

export { IconChevronLeft, IconChevronRight };

export const CalendarMonthContainer = styled.div`
  width: 100%;
  max-width: ${px2rem(480)};
`;

export const CalendarGrid = styled.div`
  display: grid;
  max-width: ${px2rem(480)};
  grid-template-columns: auto auto auto auto auto auto auto;
  gap: ${({ theme }) => theme.space.xs};
`;

export const CalendarMonth = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${px2rem(480)};
  height: 100vh;
  max-height: ${px2rem(415)};
  margin-top: ${({ theme }) => theme.space.xs};
  padding: 0;
`;

export const CalendarMonthCaption = styled.div`
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: ${({ theme }) => theme.space.m};
  color: ${({ theme }) => theme.customization.colors.text.default};
`;

const TextAsTH = styled((props: ComponentProps<typeof Text>) => <Text as="div" {...props} />)`
  color: ${({ theme }) => theme.customization.colors.text.default};
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const CalendarMonthDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${px2rem(480)};
`;

export const CalendarDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${px2rem(480)};
`;

type CalendarDayTextProps = ComponentProps<'div'>;

export const CalendarDayText: React.FC<Omit<CalendarDayTextProps, 'ref'>> = ({ ...props }) => (
  <TextAsTH as="div" paddingBottom="xs" textTransform="uppercase" variant="micro" textAlign="center" {...props} />
);

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  justify-content: space-between;
`;

export const ButtonPrev = styled(Button)`
  height: ${px2rem(33)};
  position: relative;
  z-index: 10;
`;

export const ButtonNext = styled(Button)`
  height: ${px2rem(33)};
  position: relative;
  z-index: 10;
`;
