import { IconRestaurantCutlery, IconMagnifyingglass } from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons';
import { Heading } from '@/components/Heading/Heading';
import styled from '@emotion/styled';

export const TitleContainer = styled(Heading)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 ${({ theme }) => theme.space.halfL};
  gap: ${({ theme }) => theme.space.s};
`;

export const ListContainer = styled.div<{
  nbRestaurants: number;
}>`
  display: grid;
  gap: ${({ theme }) => `${theme.space.s} ${theme.space.s}`};

  grid-template-columns: repeat(1, minmax(200px, 345px));

  ${'@media (min-width: 740px)'} {
    grid-template-columns: repeat(${({ nbRestaurants }) => Math.min(nbRestaurants, 2)}, 345px);
  }

  ${'@media (min-width: 1100px)'} {
    grid-template-columns: repeat(${({ nbRestaurants }) => Math.min(nbRestaurants, 3)}, 345px);
    gap: ${({ theme }) => `${theme.space.m} ${theme.space.m}`};
  }

  justify-content: center;
  gap: ${({ theme }) => `${theme.space.m} ${theme.space.m}`};
`;

export const RestaurantCutlery = styled(IconRestaurantCutlery)`
  width: 24px;
  height: 24px;
`;

export const Magnifyingglass = styled(IconMagnifyingglass)`
  margin-right: ${({ theme }) => theme.space.s};
`;

export const PaginationActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space.s};
`;
