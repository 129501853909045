import { GetRestaurantFragment } from '@/graphql/types.generated';

export type GoogleMapsUrlParams = {
  name: string;
  address: GetRestaurantFragment['address'];
};

export const googleMapsUrl = ({ name, address: { country, zipCode, city, address } }: GoogleMapsUrlParams) => {
  const url = new URL('https://www.google.com/maps/search/');
  url.searchParams.append('api', '1');
  url.searchParams.append('query', `${name} ${country} ${zipCode} ${city} ${address}`);
  return url.href;
};
